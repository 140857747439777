<template>
  <div id="app">
    <navbar-element
      v-if="showNavbar"
      :logo-image="logoImage"
      :show-site-overview="showSiteOverview"
      :expanded="expanded"
      :arrow="arrow"
      :show-site-id="showSiteId"
      :site-id="siteId"
      :link-to-help-center="linkToHelpCenter"
      :show-logout="showLogout"
      @click="toggleDropdown"
      @getLinkToEditor="getLinkToEditor"
      @logout="logout"
    />
    <router-view @authenticated="setAuthenticated" />
  </div>
</template>

<script>
import { Navbar } from "@monosolutions/vue-components";
import { mapGetters } from "vuex";
import { $ss } from "@/plugins/localstorage";
import HeapMixin from "@/mixins/HeapMixin";

export default {
  name: "App",
  components: { "navbar-element": Navbar },
  mixins: [HeapMixin],
  data() {
    return {
      logoImage: "/mono.site_logo_white.png",
      showSiteOverview: false,
      showLogout: true,
      showSiteId: true,
      arrow: true,
      expanded: false,
      siteId: "",
      linkToHelpCenter: "https://help.mono.site/hc/da",
    };
  },
  async beforeMount() {
    let st = new URLSearchParams(window.location.search).get("st");
    let acc = new URLSearchParams(window.location.search).get("a");
    let uf = new URLSearchParams(window.location.search).get("uf");

    if (st && acc) {
      await this.$store.dispatch("accountcenter/setAuthenticated", true);
      this.$cookies.set("sid", st, "1d", "/", ".account.mono.site", true, "Strict");
      $ss.set("jwtToken", st);

      let { siteId, resellerId, accountId } = acc.split(".");

      await this.$store.dispatch("accountcenter/setAccountInfo", {
        siteId,
        resellerId,
        accountId,
      });

      if (uf) {
        this.$router.push({ path: "/upgrade/check-domain" });
      } else {
        this.$router.replace({ name: "profile" });
      }
    }
  },
  async mounted() {
    if ($ss.get("user") && this.$route.name !== "login") {
      await this.$store.dispatch("accountcenter/setAuthenticated", true);
    }
    this.loadHeap();
    document.addEventListener("click", this.close);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
  computed: {
    ...mapGetters({
      siteInfo: "accountcenter/siteInfo",
      authenticated: "accountcenter/authenticated",
      hideNavigation: "accountcenter/hideNavigation",
    }),
    showNavbar() {
      return this.authenticated && !this.hideNavigation;
    },
  },
  methods: {
    async setAuthenticated() {
      await this.$store.dispatch("accountcenter/getSiteInfo");
    },
    async logout() {
      await this.$store.dispatch("accountcenter/setAuthenticated", false);
      $ss.remove("user");
      $ss.remove("jwtToken");
      this.$cookies.remove("sid");
      this.$router.replace({ name: "login" });
    },
    toggleDropdown() {
      this.expanded = !this.expanded;
      this.siteId = this.siteInfo.id.toString();
    },
    close(e) {
      if (!(e.target.tagName === "svg" || e.target.tagName === "use")) {
        this.expanded = false;
      }
    },
    async getLinkToEditor() {
      const link = await this.$store.dispatch("accountcenter/getLinkToEditor");
      window.open(link.fullLoginUrl, '_blank');
    }
  },
};
</script>

<style lang="scss">
#app {
  height: 100%;
  overflow: hidden;
  font-family: "Roboto", sans-serif, sans-serif;
  color: #3e3e3e;
  -webkit-font-smoothing: antialiased;
}
</style>
