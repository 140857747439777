<template>
    <div class="upgrade-plan-wrapper" :class="{ 'most-popular': isMostPopular }">
        <div v-if="isMostPopular" class="most-popular-header mds-capital-small">
            {{ $t(mostPopular) }}
        </div>
        <div class="plan-content" :class="{ 'most-popular': isMostPopular }">
            <div class="plan-upper">
                <h4 class="mds-heading-four plan-title">
                    {{ $t(name) }}
                </h4>
                <p class="mds-body-medium plan-subtitle">
                    {{ $t(description) }}
                </p>
                <div class="price">
                    <span class="price-value mds-heading-two">{{ price }}</span><span class="price-currency mds-heading-five">{{ currency }}</span>
                </div>
                <span class="price-frequency mds-body-medium mds-grey-500">
                    {{ $t('accountCenter.plans.diy.upgradePlan.priceInterval') }}
                </span>
                <mv-button color="blue"
                           size="medium"
                           type="solid"
                           :disabled="isCurrentPlan"
                           :text="$t('accountCenter.plans.diy.upgradePlan.selectPlan')"
                           @click="$emit('selected', planKey)"
                />
            </div>
            <div class="plan-lower">
                <h6 class="includes-heading mds-heading-six">
                    {{ $t(features.title) }}
                </h6>
                <ul>
                    <li v-for="(feature, index) in featureList" :key="index" class="mds-body-medium">
                        {{ $t(feature) }}
                    </li>
                </ul>
            </div>
            <div v-if="isMobile()" class="mobile-feature-collapse" @click="collapsed = !collapsed">
                <span class="mds-body-medium">
                    {{ $t('accountCenter.plans.diy.upgradePlan.showFeatures') }}
                </span>
                <mv-icon :type="(collapsed ? 'arrow-down' : 'arrow-up')" class="arrow" />
            </div>
        </div>
    </div>
</template>

<script>
import UpgradeFlowMixin from '@/mixins/UpgradeFlowMixin';
import { MvButton, MvIcon } from '@monosolutions/vue-components';

export default {
    name: 'upgrade-plan',
    components: { MvButton, MvIcon },
    mixins: [ UpgradeFlowMixin ],
    props: {
        planKey: {
            type: String,
            'default': ''
        },
        name: {
            type: String,
            'default': ''
        },
        isCurrentPlan: {
            type: Boolean,
            'default': false
        },
        description: {
            type: String,
            'default': ''
        },
        price: {
            type: Number,
            'default': 0
        },
        features: {
            type: Object,
            'default': () => ({})
        },
        mostPopular: {
            type: String,
            'default': ''
        }
    },
    data() {
        return {
            collapsed: true
        };
    },
    computed: {
        isMostPopular() {
            return this.mostPopular !== '';
        },
        featureList() {
            return this.features.list.filter((feature, index) => {
                return this.isMobile() && this.collapsed ? index < 3 : true;
            });
        }
    },
    methods: {
        isMobile() {
            return this.$mq === 'mobile';
        }
    }
};
</script>