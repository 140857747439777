import store from '@/store';
import { get } from 'lodash';

export default {
    check(limitString) {
        const { userRole, limitations } = store.state.accountcenter;

        // UserRole doesn't exist
        // eslint-disable-next-line no-prototype-builtins
        if (!limitations.hasOwnProperty(userRole)) {
            return false;
        }

        // No key
        if (!limitString) {
            return true;
        }

        const separator = limitString.indexOf('||') > -1 ? '||' : '&&';

        const limits = limitString.split(separator).map(originalLimit => {
            let limit = originalLimit;
            const hasNegation = limit.indexOf('!') === 0;

            limit = hasNegation ? limit.replace(/!/, '') : limit;
            let allowed = get(limitations[userRole], limit.trim());

            // If limitation was not added we default to false
            allowed = allowed === undefined ? false : allowed;

            return { allowed: hasNegation ? !allowed : allowed };
        });

        if (separator === '||') {
            return limits.some(limit => limit.allowed);
        }

        return limits.every(limit => limit.allowed);
    }
};