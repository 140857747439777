<template>
  <div class="container login-container">
    <a :href="link" class="link" target="_blank">
      <img src="../../assets/Mono.site_Logo_resized.png" class="mono-site-logo"/>
    </a>
    <div class="login-form col-md-4">
      <h2 class="headline">{{ $t('accountCenter.login.headline') }}</h2>
      <input-text class="mds-input-text" label="accountCenter.general.username" index="username" :value="model.username" @change="setData" />
      <input-text class="mds-input-text" type="password" label="accountCenter.general.password" index="password" :value="model.password" @change="setData" />
      <div v-if="(!loginAuthenticated && model.username === '') || wrongCredentials" class="warningBox mono-size-tiny red">
       {{ errorMessage }}
      </div>
      <mv-button class="button" size="medium" text="accountCenter.login.button" :isLoading="loginLoading" @click="login" />
      <span class="link"><a :href="link" class="link mono-size-tiny" target="_blank">{{ $t('accountCenter.login.goBack') }}</a></span>
    </div>
  </div>
</template>

<script>
import { set, merge } from 'lodash';
import axios from 'axios';
import { MvButton, InputText } from "@monosolutions/vue-components";
import { $ss } from '@/plugins/localstorage';

export default {
  name: "LoginForm",
  components: { MvButton, InputText },
  data: () => {
    return {
      model: {
        username: "",
        password: "",
        ticket: ""
      },
      link: "https://www.mono.site",
      loginAuthenticated: true,
      wrongCredentials: false,
      errorMessage: '',
      loginLoading: false
    };
  },
  created() {
    document.body.style.backgroundColor = "#FFF";
  },
  methods: {
    setData(payload) {
      const objData = {};
      set(objData, payload.index, payload.data.value);
      this.model = merge({}, this.model, objData);
    },
    async login() {
      if (this.model.username !== "" && this.model.password !== "") {
        this.loginLoading = true;
        this.wrongCredentials = false;
        const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

        const body = {
          email: this.model.username,
          password: this.model.password
        };

        try {
          const response = await axios.post(
            `${accountCenterBaseUrl}accounts/login`,
            body,
            {
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json'
              },
            }
          );

          if(response.status === 200 && response.data){
            const siteInfo = await this.$store.dispatch("accountcenter/getSiteInfo");
            if (siteInfo.isActive !== 1) {
              this.loginAuthenticated = false;
              this.errorMessage = this.$t('accountCenter.login.errors.terminated');
              return;
            }
            this.$store.dispatch('accountcenter/hideNavigation', false);
            localStorage.setItem('loginTicket', response.data.ticket);
            await this.$store.dispatch('accountcenter/setAccountInfo', response.data.accountInfo);
            $ss.set('user', JSON.stringify(response.data.accountInfo));
            $ss.set('jwtToken', response.data.accountInfo);
            await this.$store.dispatch("accountcenter/setAuthenticated", true); 
            this.loginLoading = false;
            if ($ss.get('jwtToken') != null){     
                if(this.$route.params.nextUrl != null){         
                    this.$router.push(this.$route.params.nextUrl)         
                }
                else {         
                    this.$router.push('account')                                
                }
            }
          }

          return false;
        } catch (error) {
          this.wrongCredentials = true;
          this.loginAuthenticated = false;
          this.loginLoading = false;
          this.errorMessage = this.$t('accountCenter.login.errors.credentials');
          console.error('ACCOUNT CENTER LOGIN ERROR', error);
          throw error;
        }

      } else {
        this.errorMessage = this.$t('accountCenter.login.errors.empty');
        this.loginAuthenticated = false;
      }
    },
  },
};
</script>
