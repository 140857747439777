<template>
    <mv-modal
        title="accountCenter.subscription.terminateModal.stepOne.title"
        titlePosition="center"
        firstButtonText="accountCenter.subscription.terminateModal.buttons.keepSite"
        firstButtonColor="white"
        :firstButton="true"
        secondButtonText="accountCenter.subscription.terminateModal.buttons.cancelNow"
        secondButtonColor="red"
        :secondButtonLoading="secondButtonLoading"
        @first-button-clicked="$emit('closeTerminateModal')"
        @second-button-clicked="terminateSubscription"
        @modal-close-clicked="$emit('closeTerminateModal')"
    >
        <div slot="mv-modal-content">
            <h4 class="mds-heading-four terminate-title">{{ $t('accountCenter.subscription.terminateModal.stepOne.preamble') }}</h4>
            <p class="terminate-paragraph">{{ $t('accountCenter.subscription.terminateModal.stepOne.secondLine') }}</p>
            <span class="icon"><p class="terminate-paragraph icon">{{ $t('accountCenter.subscription.terminateModal.stepOne.paragraphOne') }}</p></span>
            <span class="icon"><p class="terminate-paragraph icon">{{ $t('accountCenter.subscription.terminateModal.stepOne.paragraphTwo') }}</p></span>
            <span class="icon"><p class="terminate-paragraph icon">{{ $t('accountCenter.subscription.terminateModal.stepOne.paragraphFour') }}</p></span>
            <span class="icon"><p class="terminate-paragraph icon">{{ $t('accountCenter.subscription.terminateModal.stepOne.paragraphFive') }}</p></span>   
        </div>
    </mv-modal>
</template>

<script>
import { MvModal } from '@monosolutions/vue-components';

export default {
    name: 'errors-modal',
    components: { MvModal },
    props: {
        title: {
            type: String,
            'default': ''
        },
        body: {
            type: String,
            'default': ''
        },
        secondButtonLoading: {
            type: Boolean,
            'default': false
        }
    },
    methods: {
        terminateSubscription() {
            this.$emit('terminateFreeWebsite');
        }
    }
};
</script>
