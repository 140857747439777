import CheckDomain from '@/views/upgradeFlow/CheckDomain';
import Checkout from '@/views/upgradeFlow/Checkout';
import Subscription from '@/views/upgradeFlow/Subscription';
import Confirmation from '@/views/upgradeFlow/Confirmation';

const routes = [
    {
        path: 'check-domain',
        name: 'check-domain',
        title: 'Check Domain',
        component: CheckDomain
    },
    {
        path: 'checkout',
        name: 'diy.checkout',
        title: 'Checkout',
        component: Checkout
    },
    {
        path: 'subscription',
        name: 'subscription-flow',
        title: 'Subscription',
        component: Subscription
    },
    {
        path: 'confirmation',
        name: 'diy.confirmation',
        title: 'Confirmation',
        component: Confirmation
    }
];

export default routes;