<template>
    <div :class="{ loading: isLoading }">
        <div class="diySubscription">
            <p class="heading">
                {{ $t('accountCenter.plans.diy.upgradePlan.headline') }}
            </p>

            <p class="preamble">
                {{ $t('accountCenter.plans.diy.upgradePlan.preamble', {domain: domainName}) }}
            </p>

            <div class="planContainer">
                <upgrade-plan v-for="(plan, index) in plans" :key="plan.key" v-bind="plan" :is-current-plan="isCurrentPlan(plan.key)" @selected="selectPlan(index)" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UpgradeFlowMixin from '@/mixins/UpgradeFlowMixin';
import UpgradePlan from '../../components/UpgradePlan.vue';

export default {
    name: 'diy-subscription',
    components: { UpgradePlan },
    mixins: [ UpgradeFlowMixin ],
    data() {
        return {
            plans: {},
            isLoading: true
        };
    },
    computed: {
        ...mapGetters({
            domainName: 'upgradeflow/domainName'
        }),
        calculatedWidth() {
            return this.$mq === 'mobile' ? '100vw' : 800;
        }
    },
    async mounted() {
        this.settings = await this.$store.dispatch("accountcenter/getSettings");
        this.accountStatus = await this.$store.dispatch("accountcenter/getAccountStatus");
        this.plans = this.settings.plans.map(plan => {
            plan.price = plan.relatedPrice;
            return plan;
        });
        this.isLoading = false;
    },
    methods: {
        selectPlan(index) {
            this.$store.dispatch('upgradeflow/addItemToCheckout', this.plans[index]);
            this.$router.push({ path: '/upgrade/checkout' });
        },
        isCurrentPlan(key) {
            return key === this.accountStatus.status;
        }
    }
};
</script>
