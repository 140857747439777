<template>
    <div class="domain-checker">
        <div class="contents">
            <div class="domain-checker-container">
                <div class="domain-element domain-input">
                    <div class="input-field">
                        <span class="prefix" :class="{ 'prefix-searching': domainSearching, 'prefix-success': domainAvailable, 'prefix-error': !validDomain }">https://</span>
                        <input-text
                            :width="50"
                            :value="fullDomain"
                            :valid="validDomain"
                            :disabled="domainSearching"
                            :class="{ 'domain-checker-input': validDomain, 'domain-checker-input-error': !validDomain, 'domain-checker-input-success': domainAvailable, 'input-success': domainAvailable, 'input-error': !validDomain }"
                            class="input-field"
                            @change="updateDomain"
                        />
                        <span v-if="!validDomain" class="alert">
                            <mv-icon type="alert-stroke" />
                        </span>
                        <span v-if="domainAvailable" class="success">
                            <mv-icon type="check" />
                        </span>
                    </div>
                    <p v-if="!validFullDomain || searchError" class="domain-error">
                        {{ validFullDomain ? searchError : domainError }}
                    </p>
                    <p v-if="domainAvailable" class="domain-available">
                        {{ $t(domainAvailabilityText, { domain: fullDomain }) }}
                    </p>
                </div>
                <div class="domain-element domain-button">
                    <template v-if="!domainSearching">
                        <a v-if="domainAvailable" class="mds-button mds-button-medium mds-button-solid mds-button-label-only mds-heading-six register button" @click="saveDomain">
                            {{ $t('accountCenter.domainManagement.findDomain.choose') }}
                        </a>
                        <a v-else :disabled="!validDomain" class="mds-button mds-button-medium mds-button-solid mds-button-label-only mds-heading-six check button" @click="validateBeforeSearch">
                            {{ $t('accountCenter.domainManagement.findDomain.check') }}
                        </a>
                    </template>
                    <a v-else class="mds-button mds-button-medium mds-button-solid mds-button-label-only mds-heading-six searching button" disabled>
                        <span hidden class="spinner" /> {{ $t('accountCenter.domainManagement.findDomain.check') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { MvIcon, InputText } from '@monosolutions/vue-components';

export default {
    components: { InputText, MvIcon },
    data() {
        return {
            validDomain: true,
            domainAvailable: null,
            domainSearching: false,
            fullDomain: '',
            searchError: '',
            domainError: '',
        };
    },
    computed: {
        domainAvailabilityText() {
            if (this.searchError) return this.searchError;
            return `accountCenter.domainManagement.findDomain.${this.domainAvailable ? 'available' : 'notAvailable'}`;
        },
        validFullDomain() {
            if (this.fullDomain === '') return true;
            if (this.domainError !== '') return false;
            return /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,12}$/.test(this.fullDomain);
        },
    },
    async mounted() {
        this.accountCenterSettings = await this.$store.dispatch("accountcenter/getSettings");
    },
    methods: {
        async checkAvailability() {
            this.domainSearching = true;
            this.searchError = '';
            const validatedDomain = this.getValidDomain();
            try {
                const response = await this.$store.dispatch("accountcenter/checkDomainIsAvailable", {
                    domain: validatedDomain
                });
                this.domainAvailable = response.status === 200 && response.data.available;
                this.searchError = !this.domainAvailable ? this.$t('accountCenter.domainManagement.findDomain.taken') : null;
                this.validDomain = !this.searchError;
                this.domainSearching = false;
            } catch (error) {
                this.searchError = error.toString();
                this.domainSearching = false;
            }
        },
        updateDomain(payload) {
            this.fullDomain = payload.data.value;
            this.domainAvailable = null;
            this.validDomain = true;
            this.searchError = '';
            this.domainError = '';
        },
        async validateBeforeSearch() {
            if (!this.validFullDomain) {
                this.validDomain = false;
                this.domainError = /\.[a-z]{2,12}$/.test(this.fullDomain)
                    ? this.$t('accountCenter.domainManagement.diy.invalidCharacter')
                    : this.$t('accountCenter.domainManagement.diy.noTld');
            } else if (!this.endsWithOneOfValidTLDs()) {
                this.validDomain = false;
                this.domainError = this.$t('accountCenter.domainManagement.diy.unsupportedTld');
            } else {
                await this.checkAvailability();
            }
        },
        endsWithOneOfValidTLDs() {
            const validatedDomain = this.getValidDomain();
            const firstDot = validatedDomain.indexOf('.');
            const tld = validatedDomain.substring(firstDot);
            return this.accountCenterSettings.domain.tld.includes(tld) || false;
        },
        getValidDomain() {
            return this.fullDomain.includes('www.') ? this.fullDomain.replace('www.', '') : this.fullDomain;
        },
        saveDomain() {
            this.$store.dispatch('upgradeflow/setDomainName', this.fullDomain);
            this.$router.push({ path: '/upgrade/subscription' });
        }
    },

};
</script>