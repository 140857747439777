<template>
  <div class="domains">
    <header-element
      headline="accountCenter.domains.headline"
      firstButtonName="add domain"
      firstButtonText="accountCenter.domains.buttons.addDomain"
      :hasFirstButton="true"
      :enableFirstButton="isPremium"
      @first-button-clicked="showAddDomainModal"
    />
    <limitation-element
      :showLimitationModal="!isPremium && showLimitationModal"
      :color="color"
      firstHeadline="accountCenter.domains.limitation.headline"
      @modal-close-clicked="closeLimitationModal"
    >
      <div slot="limitation-content">
        <p>{{ $t('accountCenter.domains.limitation.preamble') }} <a href="javascript:void(0)" @click="openUpgradeFlow" style="color: #3E18B8">{{ $t('accountCenter.domains.limitation.link') }}</a></p>
      </div>
    </limitation-element>
    <content-wrapper>
      <div slot="content-wrapper-content">
        <h4 class="mds-heading-four sudomainHeader">{{ $t('accountCenter.domains.subdomainHeadline') }}</h4>
        <div class="wrapper-inside-container subdomain">
          <h4 class="mds-heading-four fixedHeadline">{{ subdomain }}.mono.site</h4>
          <mv-button 
            v-if="!isPremium"
            text="accountCenter.domains.buttons.upgrade" 
            name="upgrade to add domain" 
            color="purple" 
            class="button secondButton" 
            @click="openUpgradeFlow" />
        </div>
      </div>
    </content-wrapper>
    <div :class="{ modalWrapper: addDomainModal.show }">
      <modal-wizard v-if="addDomainModal.show">
        <step-content 
          title="accountCenter.domains.addDomainModal.stepOne.title"
          firstButtonText="accountCenter.general.back" 
          :firstButton="false"
          firstButtonColor="white"
          :secondButtonDisabled="nextDisabled"
          :isLoadingSecond="nextLoading"
          secondButtonText="accountCenter.general.next"
          secondButtonColor="blue"
          @firstButtonClicked="changeStep(0)"
          @secondButtonClicked="changeStep(1)"
          @modalCloseClicked="modalCloseClicked"
        >
          <div slot="step-content">
            <input type="text" :class="domainError ? 'errorMessage' : ''" placeholder="https://www.example.com" @input="inputChange" />
            <span v-if="domainCheckLoading" class="smallBlueLoading" />
            <mv-icon v-if="domainCheckIcon" :type="domainCheckIcon" class="domainInputIcon" :class="domainCheckIcon"/>
            <span v-if="domainError" class="mds-body-small domainErrorMessage">{{ $t(`${domainError}`) }}</span>
          </div>
        </step-content>  
        <step-content 
          title="accountCenter.domains.addDomainModal.stepTwo.title"
          :firstButton="false"
          secondButtonText="accountCenter.general.ok"
          secondButtonColor="blue"
          @secondButtonClicked="modalCloseClicked"
          @modalCloseClicked="modalCloseClicked"
        >
          <div slot="step-content">
            <div class="existingDomainMessage">
              <p>{{ $t('accountCenter.domains.addDomainModal.stepTwo.message.lineOne') }}</p>
              <p>{{ $t('accountCenter.domains.addDomainModal.stepTwo.message.lineTwo') }}<a href="https://help.mono.site/hc/da/articles/4412607798673-Peg-et-eksisterende-dom%C3%A6ne">{{ $t('accountCenter.domains.addDomainModal.stepTwo.message.link') }}</a>{{ $t('accountCenter.domains.addDomainModal.stepTwo.message.lastLine') }}</p>
            </div>
          </div>
        </step-content>
      </modal-wizard>
    </div>
    <span v-if="tableLoading" class="loading" />
    <table-field 
      v-if="isPremium && !tableLoading && domainList.length"
      :columns="table.columns"
      :content="domainList"
      :isLoading="priamryDomainUpdating"
      headerColor='white'
      toggleColor='blue'
      :toggleDisabled="false"
      toggleIconType='toggle'
      @toggleChange="changePrimaryDomain"
      @iconClicked="iconClicked"
    />
     <!-- CONFIRM DOMAIN REMOVAL MODAL-->
    <div v-if="showRemoveDomainModal" class="modalWrapper">
      <mv-modal
        title="accountCenter.domains.removeDomainModal.title"
        firstButton
        secondButton
        firstButtonText="accountCenter.general.cancel"
        secondButtonText="accountCenter.domains.removeDomainModal.button.text"
        :secondButtonDisabled="removeButtonDisabled"
        firstButtonColor="white"
        @first-button-clicked="closeRemoveDomainModal"
        @second-button-clicked="removeSiteDomain"
        @modal-close-clicked="closeRemoveDomainModal"
      >
        <div slot="mv-modal-content">
          <p class="removeDomainModalText">{{ $t('accountCenter.domains.removeDomainModal.preamble') }} <b>{{ domainToRemove }}</b>?</p>
        </div>
        <div v-if="errorMessage">
            {{ errorMessage }}
        </div>
      </mv-modal>
    </div>
    <mv-icon-sprite />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  Header,
  MvIconSprite,
  ContentWrapper,
  Limitation,
  MvButton,
  ModalWizard,
  StepContent,
  MvIcon,
  TableField,
  MvModal,
} from "@monosolutions/vue-components";

export default {
  name: "ManageDomain",
  components: {
    "header-element": Header,
    MvIconSprite,
    ContentWrapper,
    "limitation-element": Limitation,
    MvButton,
    "table-field": TableField,
    MvModal,
    ModalWizard,
    StepContent,
    MvIcon,
  },
  data: () => {
    return {
      color: "purple",
      upgradeLink: "",
      addDomainModal: {
        show: false,
      },
      domainError: '',
      nextDisabled: true,
      nextLoading: false,
      options: [ 
        { label: "Connect to an existing domain", value:"op2", checked: false, text: "I already have a domain at a provider" }
      ],
      pointedDomain: '',
      table: {
        columns: [
          {
              key: 'url',
              title: 'accountCenter.domains.table.url'
          },
          {
              key: 'isPrimary',
              title: 'accountCenter.domains.table.primaryDoamin',
              type: 'toggle'
          },
          {
              key: 'type',
              title: 'accountCenter.domains.table.type'
          },
          {
              key: 'delete',
              title: '',
              type: 'icon',
              clickable: true
          },
        ],
      },
      domainList: [],
      showRemoveDomainModal: false,
      domainToRemove: '',
      errorMessage: '',
      showLimitationModal: true,
      domainIsAvailable: false,
      domainCheckLoading: false,
      priamryDomainUpdating: false,
      loadingTable: true,
      removeButtonDisabled: false
    };
  },
  computed: {
    ...mapGetters({
      siteInfo: "accountcenter/siteInfo",
      domainSiteInfo: "accountcenter/domainSiteInfo",
    }),
    isPremium() {
      return this.siteInfo.siteUserGroups !== 'mono_site_start';
    },
    subdomain() {
      return this.siteInfo.subdomain;
    },
    tableLoading() {
      return this.isPremium && this.loadingTable;
    },
    domainCheckIcon() {
      if (this.domainError) {
        return 'alert-stroke';
      } else if (this.domainIsAvailable) {
        return 'check';
      }
      return '';
    }
  },
  async mounted() {
    await this.loadDomains();
  },
  methods: {
    closeLimitationModal() {
      this.showLimitationModal = false;
    },
    async loadDomains() {
      await this.$store.dispatch("accountcenter/getSiteDomains");
      const domainsArray = this.domainSiteInfo?.map((record, index) => { 
        const domainInfo = {
          isPrimary: false,
          url: record.domain,
          'delete': 'delete',
          type: record.isMono ? 'Mono.site' : this.$t('accountCenter.domains.table.connected')
        };
        if (index === 0 ) {
          domainInfo.isPrimary = true;
        }

        return domainInfo;
      });
      this.domainList = domainsArray;
      this.priamryDomainUpdating = false;
      this.loadingTable = false;
    },
    async changePrimaryDomain(rowData) {
      //toggleOff new priamry before it's changed in db
      if (!rowData.index) {
        document.getElementById(`toggle_${rowData.index}_toggle`).checked = true;
        return;
      }
      document.getElementById(`toggle_${rowData.index}_toggle`).checked = false;
      const newPrimaryDomain = rowData.rows[rowData.index]?.url;

      //set new priamry domain
      if (newPrimaryDomain) {
        this.priamryDomainUpdating = true;
        const response = await this.$store.dispatch("accountcenter/setPrimaryDomain", {
          domain: newPrimaryDomain,
          ticket: localStorage.getItem('loginTicket') || '',
        });
        if (response.status === 200) {
          await this.loadDomains();
          return;
        } 
      }
    },
    iconClicked(rowData) {
      this.showRemoveDomainModal = true;
      this.domainToRemove = this.domainList[rowData.rowIndex]?.url;
    },
    closeRemoveDomainModal() {
      this.showRemoveDomainModal = false;
    },
    async removeSiteDomain() {
      this.removeButtonDisabled = true;
      if (this.domainToRemove) {
        const response = await this.$store.dispatch("accountcenter/removeSiteDomain", {
          domain: this.domainToRemove,
        });
        if (response.status === 200) {
          this.showRemoveDomainModal = false;
          await this.loadDomains();
        }
      } else {
        this.errorMessage = this.$t('accountCenter.domains.errors.removeDomain');
      }
      this.removeButtonDisabled = false;
    },
    showAddDomainModal() {
      this.addDomainModal.show = true;
    },
    modalCloseClicked() {
      this.addDomainModal.show = false;
      this.nextDisabled = true;
      this.domainError = '';
      this.domainIsAvailable = false;
    },
    async checkDomainIsAvailable() {
      this.domainCheckLoading = true;
      this.domainIsAvailable = false;
      const domainName = this.extractDomainName();
      const response = await this.$store.dispatch("accountcenter/checkDomainIsAvailable", {
           domain: domainName,
      });
      this.domainIsAvailable = response.data.available;
      this.domainCheckLoading = false;
      if (!this.domainIsAvailable) {
        this.domainError = this.$t('accountCenter.domains.errors.domainIsAssigned');
      }
      this.nextDisabled = false;
    },
    // eslint-disable-next-line no-unused-vars
    async addSiteDomain() {
      const domainName = this.extractDomainName();

      if (this.domainIsAvailable) {
        const response = await this.$store.dispatch("accountcenter/addSiteDomain", {
          domain: domainName,
        });
        if (response.status === 200) {
          return true;
        }
      }
      return false;
    },
    async changeStep(step) {
      this.nextDisabled = true;
      this.nextLoading = true;
      if (this.pointedDomain && step === 1) {
        const domainAdded = await this.addSiteDomain();
        if (!domainAdded) {
          return;
        }
        await this.loadDomains();
      } 
      this.$root.$emit('modalWizardNexStep',step); 
      this.domainIsAvailable = false;
      this.nextLoading = false;
    },
    async inputChange(payload) {
      this.domainError = '';
      this.nextDisabled = true;
      this.pointedDomain = payload.target.value;
      if (payload.target.value !== '' ) {
        if (this.validPointedDomain()) {
          await this.checkDomainIsAvailable();
        } else {
          this.domainError = this.$t('accountCenter.domains.errors.invalidDomain');
        }
      }
    },
    validPointedDomain() {
      return this.pointedDomain ? /^(http[s]?:\/\/)?(www\.)?(?!www\.)([a-zA-Z0-9][a-zA-Z0-9]{1,61}[a-zA-Z0-9])(\.[a-zA-Z]{2,}){1,}$/.test(this.pointedDomain) : false;
    },
    extractDomainName() {
      return this.pointedDomain.replace(/^(http[s]?:\/\/)?(www\.)?/, "");
    },
    openUpgradeFlow() {
      this.$router.push({ path: '/upgrade/check-domain' });
    },
  }
};
</script>