import axios from 'axios';

axios.defaults.withCredentials = true;

const state = {
    routeBeforeEnterUpgradeFlow: '/',
    cart: {
        plan: {},
        addOns: [],
        discount: false
    },
    domainName: '',
    customerInfo: {},
    domainRegistrationSkipped: false
};

const mutations = {
    SET_ROUTE_BEFORE_ENTER_UPGRADE_FLOW(state, route) {
        state.routeBeforeEnterUpgradeFlow = route;
    },
    ADD_PLAN_TO_CHECKOUT(state, plan) {
        state.cart.plan = plan;
    },
    ADD_ADDON_TO_CHECKOUT(state, addOn) {
        state.cart.addOns.push(addOn);
    },
    SET_DISCOUNT(state, discount) {
        state.cart.discount = discount;
    },
    SET_DOMAIN_NAME(state, domain) {
        state.domainName = domain;
    },
    SET_CUSTOMER_INFO(state, customerInfo) {
        state.customerInfo = customerInfo;
    },
    SET_DOMAIN_REGISTRATION_SKIPPED(state, skipped) {
        state.domainRegistrationSkipped = skipped;
    }
};

const actions = {
    setRouteBeforeEnterUpgradeFlow({ commit }, route) {
        commit('SET_ROUTE_BEFORE_ENTER_UPGRADE_FLOW', route);
    },
    addItemToCheckout({ commit, rootGetters }, item) {
        const accountCenterSettings = rootGetters['accountcenter/accountCenterSettings'];
        const planArray = Object.keys(accountCenterSettings.plans).map(key => accountCenterSettings.plans[key]);
        const planKeys = planArray.map(plan => plan.key);
        if (planKeys.includes(item.key)) {
            commit('ADD_PLAN_TO_CHECKOUT', item);
        } else {
            commit('ADD_ADDON_TO_CHECKOUT', item);
        }
    },
    setDiscount({ commit }, discount) {
        commit('SET_DISCOUNT', discount);
    },
    setDomainName({ commit }, domain) {
        commit('SET_DOMAIN_NAME', domain);
    },
    setCustomerInfo({ commit }, customerInfo) {
        commit('SET_CUSTOMER_INFO', customerInfo);
    },
    setDomainRegistrationSkipped({ commit }, skipped) {
        commit('SET_DOMAIN_REGISTRATION_SKIPPED', skipped);
    },
};

const getters = {
    routeBeforeEnterUpgradeFlow: state => state.routeBeforeEnterUpgradeFlow,
    checkoutItems: state => state.cart,
    domainName: state => state.domainName,
    customerInfo: state => state.customerInfo,
    domainRegistrationSkipped: state => state.domainRegistrationSkipped
};

export default {
    state,
    mutations,
    actions,
    getters
};