<template>
    <div class="order-summary-wrapper">
        <div class="order-summary-container">
            <span class="mds-capital-medium order-summary-heading">
                {{ $t('accountCenter.diy.orderSummary.title') }}
            </span>
            <div v-for="(item, index) in checkOutItemsPurged" :key="index" class="cart-item">
                <span class="cart-item-name mds-body-large">{{ $t(item.name) }}</span>
                <span class="cart-item-price mds-body-large">
                    {{ formatPricingString(item) }}
                </span>
                <span class="cart-item-subtitle mds-body-medium mds-grey-700">
                    {{ $t(item.subtitle ) }}
                </span>
            </div>
            <div v-if="showCupon" class="cart-coupon">
                <mv-button
                    v-if="!addCouponActive"
                    color="grey"
                    size="medium"
                    type="solid"
                    :text="$t('accountCenter.diy.orderSummary.addCoupon')"
                    @click="setAddCouponActive"
                />
                <div v-if="addCouponActive" class="add-coupon-active">
                    <input-text
                        v-if="!couponValidityChecked || (couponValidityChecked && !couponIsValid)"
                        index="coupon"
                        class="coupon-field"
                        :placeholder="$t('accountCenter.diy.orderSummary.addCoupon')"
                        :value="couponPromoCode"
                        :valid="!couponValidityChecked || (couponValidityChecked && couponIsValid)"
                        :error-message="$t('accountCenter.diy.orderSummary.errors.couponNotValid')"
                        @change="setCouponValue"
                    />
                    <div v-if="couponValidityChecked && couponIsValid" :class="[{validCoupon: couponValidityChecked && couponIsValid}]">
                        <mv-icon class="tag-icon" type="tag" />
                        <span> {{ couponPromoCode }} </span>
                        <mv-icon class="close-icon" type="close" @click="clearCoupon" />
                    </div>
                    <div class="apply-coupon">
                        <mv-button
                            v-if="!couponValidityChecked || (couponValidityChecked && !couponIsValid)"
                            color="grey"
                            size="medium"
                            type="solid"
                            class="btn-apply-coupon"
                            :text="$t('accountCenter.diy.orderSummary.applyCoupon')"
                            @click="verifyCoupon"
                        />
                        <div v-else class="amount-off">
                            -{{ discount }} {{ currency }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="cart-total">
                <span class="order-total-title mds-heading-five mds-charcoal-100">
                    {{ $t('general.total') }}
                </span>
                <div class="order-total-price mds-heading-five mds-charcoal-100">
                    <div class="order-total-price" :key="totalPrice">{{ totalPrice }}</div> <span :key="currency">{{ currency }}</span>
                </div>
                <span class="order-total-freq mds-body-medium mds-grey-700">
                    {{ billingFrequency }}
                </span>
            </div>
            <div v-if="showButton" class="order-summary-button">
                <mv-button color="blue"
                           size="medium"
                           type="solid"
                           :text="$t('accountCenter.diy.orderSummary.submitPurchase')"
                           @click="$emit('submit', { couponPromoCode })"
                />
                <div class="safe-secure">
                    <mv-icon type="verified" />
                    <span class="safe-secure-text">{{ $t('accountCenter.diy.orderSummary.safeAndSecure') }}</span>
                </div>
            </div>
        </div>
        <div v-if="showTerms" class="order-summary-terms mds-body-small mds-grey-700">
            <i18n path="accountCenter.diy.orderSummary.termsAndConditions.text">
                <a :href="$t('accountCenter.diy.orderSummary.termsAndConditions.termsAndConditionsLink')" target="_blank">{{ $t('accountCenter.diy.orderSummary.termsAndConditions.termsAndConditionsLinkText') }}</a>
                <a :href="$t('accountCenter.diy.orderSummary.termsAndConditions.privacyPolicyLink')" target="_blank">{{ $t('accountCenter.diy.orderSummary.termsAndConditions.privacyPolicyLinkText') }}</a>
            </i18n>
        </div>
        <div v-if="errorModal.show" class="modal-overlay error-modal">
            <errors-modal :title="errorModal.title" :body="errorModal.body" @closeErrorModal="closeErrorModal" />
        </div>
    </div>
</template>

<script>

import { MvButton, MvIcon, InputText } from '@monosolutions/vue-components';
import { mapGetters } from 'vuex';
import UpgradeFlowMixin from '@/mixins/UpgradeFlowMixin';
import ErrorsModal from "@/components/modals/ErrorsModal.vue";

export default {
    name: 'order-summary',
    components: { MvButton, MvIcon, InputText, ErrorsModal },
    mixins: [ UpgradeFlowMixin ],
    props: {
        showButton: {
            type: Boolean,
            'default': false
        },
        showTerms: {
            type: Boolean,
            'default': false
        },
        showCupon: {
            type: Boolean,
            'default': false
        },
        stripe: {
            type: Object,
            'default': undefined
        }
    },
    data() {
        return {
            addCouponActive: false,
            couponPromoCode: '',
            couponValidityChecked: false,
            couponIsValid: false,
            couponAmountOff: null,
            couponPercentOff: null,
            isLoading: false,
            errorModal: {
                show: false,
                title: '',
                body: ''
            }
        };
    },
    computed: {
        ...mapGetters({
            checkoutItems: 'upgradeflow/checkoutItems',
            accountCenterSettings: 'accountcenter/accountCenterSettings'
        }),
        checkOutItemsPurged() {
            const checkoutItems = Object.assign({}, this.checkoutItems);
            if (checkoutItems.addOns.length === 0) {
                delete checkoutItems.addOns;
            }

            delete checkoutItems.discount;

            return checkoutItems;
        },
        totalPrice() {
            return (this.checkoutItems.plan.relatedPrice * 12) - this.discount + this.checkoutItems.addOns.reduce((acc, item) => {
                return acc + (item.relatedPrice * 12);
            }, 0);
        },
        billingFrequency() {
            return this.$t('accountCenter.diy.orderSummary.billedYearly');
        },
        discount() {
            if (this.checkoutItems.discount) return this.checkoutItems.discount;

            if (this.couponAmountOff) return (Math.round(this.couponAmountOff) / 100).toFixed(2);

            const summedPlandPrices = this.checkoutItems.plan.relatedPrice * 12;

            if (this.couponPercentOff) return (summedPlandPrices * Math.floor(this.couponPercentOff)) / 100;

            return 0;
        }
    },
    mounted() {
        this.$store.dispatch('upgradeflow/setDiscount', 0);
    },
    methods: {
        planFrequencyAbbreviation(freq) {
            return freq === 1
                ? this.$t('accountCenter.diy.orderSummary.monthAbbreviation')
                : this.$t('accountCenter.diy.orderSummary.yearAbbreviation');
        },
        formatPricingString(item) {
            return `${item.relatedPrice} ${this.currency}/${this.planFrequencyAbbreviation(item.halBillingFrequency)}`;
        },
        setAddCouponActive() {
            this.addCouponActive = !this.addCouponActive;
        },
        async verifyCoupon() {
            try {
                const response = await this.$store.dispatch("accountcenter/verifyCupon", {
                    couponPromoCode: this.couponPromoCode
                });
                this.couponIsValid = response.data.coupon.valid;
                this.couponAmountOff = response.data.coupon.amountOff;
                this.couponPercentOff = response.data.coupon.percentOff;
                this.couponValidityChecked = true;

                if (this.couponIsValid) {
                    this.$store.dispatch('upgradeflow/setDiscount', this.discount);
                }
            } catch (error) {
                this.errorModal.show = true;
                this.errorModal.title = 'general.error';
                this.errorModal.body = 'general.error';
            }
        },
        setCouponValue(evt) {
            this.couponPromoCode = evt.data.value;
            if (this.couponPromoCode === '') {
                this.couponValidityChecked = !!this.couponPromoCode;
            }
        },
        clearCoupon() {
            this.couponPromoCode = '';
            this.couponIsValid = false;
            this.couponValidityChecked = false;
            this.couponAmountOff = null;
            this.couponPercentOff = null;
            this.$store.dispatch('upgradeflow/setDiscount', 0);
        },
        closeErrorModal() {
            this.errorModal.show = false;
        }
    }
};
</script>