<template>
    <div :class="`mds-tooltip-white mds-tooltip-medium mds-tooltip-position-${position} mds-tooltip`">
        <div class="mds-tooltip-content">
            <span class="mds-tooltip-container">
                <p class="mds-heading-six">
                    {{ $t(text) }}
                </p>
                <div class="cvv-tooltip-image" />
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'cvv-tooltip',
    props: {
        text: {
            type: String,
            'default': ''
        },
        position: {
            type: String,
            'default': 'bottomCenter'
        }
    }
};
</script>