<template>
  <div class="home">
    <header-element
      headline="accountCenter.profile.headline"
      firstButtonName="save"
      firstButtonText="Save"
      :hasFirstButton="false"
    />
    <content-wrapper>
      <div slot="content-wrapper-content">
        <p class="headline">{{ $t('accountCenter.profile.personalInformation.headline') }}</p>
        <div class="wrapper-inside-container">
          <input-text
            class="mds-input-text width-50"
            label="accountCenter.profile.personalInformation.email"
            autocomplete="do-not-autofill"
            disabled
            :value="model.email"
          />
          <mv-button
            class="button mds-button-grey profile-button"
            size="medium"
            text="accountCenter.profile.button.email"
            @click="showChangeEmailModal"
          />
          <input-text
            class="mds-input-text width-50"
            label="accountCenter.general.password"
            type="password"
            disabled
            autocomplete="do-not-autofill"
            :value="model.password"
          />
          <mv-button
            class="button mds-button-grey profile-button"
            size="medium"
            text="accountCenter.profile.button.password"
            @click="showChangePasswordModal"
          />
        </div>
      </div>
    </content-wrapper>

    <!-- CHANGE EMAIL MODAL -->
    <div v-if="changeEmailModal.show" class="modal-overlay">
      <mv-modal
        title="accountCenter.profile.button.email"
        firstButton
        secondButton
        firstButtonText="accountCenter.general.cancel"
        secondButtonText="accountCenter.profile.button.saveEmail"
        firstButtonColor="white"
        :secondButtonLoading="emailLoading"
        @first-button-clicked="closeChangeEmailModal"
        @second-button-clicked="changeEmail"
        @modal-close-clicked="closeChangeEmailModal"
      >
        <div slot="mv-modal-content">
          <input-text
            class="mds-input-text"
            label="accountCenter.profile.modals.newEmail"
            index="newEmail"
            @change="setData"
          />
          <input-text
            class="mds-input-text"
            label="accountCenter.profile.modals.reEnterEmail"
            index="newEmailConfirmation"
            @change="setData"
          />
          <div v-if="showErrorMessage" class="warningBox mono-size-tiny red">
            {{ errorMessage }}
          </div>
        </div>
      </mv-modal>
    </div>

    <!-- CHANGE PASSWORD MODAL -->
    <div v-if="changePasswordModal.show" class="modal-overlay">
      <mv-modal
        title="accountCenter.profile.button.password"
        firstButton
        secondButton
        firstButtonText="accountCenter.general.cancel"
        secondButtonText="accountCenter.profile.button.savePassword"
        firstButtonColor="white"
        :secondButtonLoading="passwordLoading"
        @first-button-clicked="closeChangePasswordModal"
        @second-button-clicked="changePassword"
        @modal-close-clicked="closeChangePasswordModal"
      >
        <div slot="mv-modal-content">
          <input-text
            class="mds-input-text"
            label="accountCenter.profile.modals.currentPassword"
            type="password"
            index="oldPassword"
            @change="setData"
          />
          <input-text
            class="mds-input-text"
            label="accountCenter.profile.modals.newPassword"
            type="password"
            index="newPassword"
            @change="setData"
          />
          <input-text
            class="mds-input-text"
            label="accountCenter.profile.modals.reEnterPassword"
            type="password"
            index="newPasswordConfirmation"
            @change="setData"
          />
          <div v-if="showErrorMessage" class="warningBox mono-size-tiny red">
            {{ errorMessage }}
          </div>
        </div>
      </mv-modal>
    </div>
    <mv-icon-sprite />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { set, merge } from "lodash";
import {
  MvButton,
  InputText,
  ContentWrapper,
  Header,
  MvIconSprite,
  MvModal,
} from "@monosolutions/vue-components";
import Joi from "joi";

export default {
  name: "ManageProfile",
  components: {
    MvButton,
    InputText,
    ContentWrapper,
    "header-element": Header,
    MvIconSprite,
    MvModal,
  },
  data: () => {
    return {
      model: {
        email: "",
        password: "randompassword",
        newEmail: "",
        newEmailConfirmation: "",
        oldPassword: "",
        newPassword: "",
        newPasswordConfirmation: "",
      },
      changeEmailModal: {
        show: false,
      },
      changePasswordModal: {
        show: false,
      },
      username: '',
      emailMatch: true,
      errorMessage: '',
      showErrorMessage: false,
      emailLoading: false,
      passwordLoading: false
    };
  },
  computed: {
    ...mapGetters({
      siteInfo: "accountcenter/siteInfo",
    }),
    emailFieldsAreValid() {
      const fields = {
        newEmail: this.model.newEmail
      };

      const validationResult = Joi.object().keys(this.emailFieldsValidationSchema).validate(fields);
      return validationResult.error ? false : true;
    },
    fieldsAreValid() {
      const fields = {
        oldPassword: this.model.oldPassword,
        newPassword: this.model.newPassword,
        newPasswordConfirmation: this.model.newPasswordConfirmation
      };

      const validationResult = Joi.object().keys(this.fieldsValidationSchema).validate(fields);
      return validationResult.error ? false : true;
    },
    emailFieldsValidationSchema() {
      const validationSchema = {
        newEmail: this.emailValidationSchema
      };

      return validationSchema;
    },
    fieldsValidationSchema() {
      const validationSchema = {
        oldPassword: this.oldPasswordValidationSchema,
        newPassword: this.newPasswordValidationSchema,
        newPasswordConfirmation: this.newPasswordValidationSchema
      };

      return validationSchema;
    },
    emailValidationSchema() {
      return Joi.string().email({ minDomainSegments: 2, tlds: false }).required();
    },
    oldPasswordValidationSchema() {
      return Joi.string().required();
    },
    newPasswordValidationSchema() {
      return Joi.string().regex(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!@#£$§%€&/([\]=?«»',;.:_~^ºª+*¨´`<>|)]).*$/).required();
    },
  },
  async mounted() {
    await this.$store.dispatch("accountcenter/getSiteInfo");
    this.model.email = this.siteInfo.email;
    this.username = this.siteInfo.username;
  },
  methods: {
    setData(payload) {
      const objData = {};
      set(objData, payload.index, payload.data.value);
      this.model = merge({}, this.model, objData);
    },
    async changeEmail() {
      if (!this.emailFieldsAreValid) {
        this.changeErrorMessage('accountCenter.profile.modals.emailNotValid', true);
        return null;
      }
      if (this.model.newEmail !== '' && this.model.newEmail === this.model.newEmailConfirmation) {
        this.emailLoading = true;
        const response = await this.$store.dispatch("accountcenter/updateSite", {
          email: this.model.newEmail,
        });
        if (response.reason && response.reason === 'EmailExists') {
          this.changeErrorMessage('accountCenter.profile.modals.emailInUse', true);
          return null;
        }
        await this.$store.dispatch("accountcenter/getSiteInfo");
        this.model.email = this.siteInfo.email;
        this.changeEmailModal.show = false;
        this.changeErrorMessage('', false);
      } else if (this.emailFieldsAreValid && this.model.newEmail !== this.model.newEmailConfirmation) {
        this.changeErrorMessage('accountCenter.profile.modals.emailError', true);
      }
    },
    async changePassword() {
      this.passwordLoading = true;
      if (this.model.oldPassword !== '' && this.model.newPassword !== '' &&
        this.model.newPassword === this.model.newPasswordConfirmation
      ) {
        if (!this.fieldsAreValid) {
          this.changeErrorMessage('accountCenter.profile.modals.passwordNotValid', true);
          return null;
        }
        const response = await this.$store.dispatch("accountcenter/updateSite", {
          username: this.username,
          password: this.model.newPassword,
          oldPassword: this.model.oldPassword
        });
        if (response.reason && response.reason === 'AccessDenied') {
          this.changeErrorMessage('accountCenter.profile.modals.passwordErrorWrong', true);
          return null;
        }
        this.changeErrorMessage('', false);
        this.changePasswordModal.show = false;
      } else if (this.model.newPassword !== this.model.newPasswordConfirmation) {
        this.changeErrorMessage('accountCenter.profile.modals.passwordErrorMatch', true);
      } else if (this.model.oldPassword === '' || this.model.newPassword === '' || this.model.newPasswordConfirmation === '') {
        this.changeErrorMessage('accountCenter.profile.modals.passwordEmpty', true);
      }
    },
    closeChangeEmailModal() {
      this.changeErrorMessage ('', false);
      this.changeEmailModal.show = false;
    },
    showChangeEmailModal() {
      this.changeEmailModal.show = true;
    },
    closeChangePasswordModal() {
      this.changeErrorMessage ('', false);
      this.changePasswordModal.show = false;
    },
    changeErrorMessage(message, show) {
      this.errorMessage = this.$t(message);
      this.showErrorMessage = show;
      this.passwordLoading = false;
      this.emailLoading = false;
    },
    showChangePasswordModal() {
      this.changePasswordModal.show = true;
    },
  },
};
</script>