/**
 * The file enables `@/watchers/index.js` to import all watchers
 * in a one-shot manner. There should not be any reason to edit this file.
 */

/* eslint-disable */
let files = require.context('.', false, /\.js$/);

const watchers = {};
files.keys().forEach(key => {
    if (/\/index\.js$/.test(key)) return;
    let fileName = key.replace(/^.*[\/\\]([^\/\\]+)\.js$/g, "$1");

    watchers[fileName] = files(key).default;

});

export default watchers;