import Vue from 'vue';
import Router from 'vue-router';
import { $ss } from '@/plugins/localstorage';
import LoginForm from '../views/login/LoginForm.vue';
import ManageProfile from '../views/profile/ManageProfile.vue';
import ManageDomains from '../views/domains/ManageDomains.vue';
import ManageSubscriptions from '../views/subscriptions/ManageSubscriptions.vue';
import { FullWidth } from "@monosolutions/vue-components";

import Upgrade from '@/views/upgradeFlow/Upgrade';
import UpgradeRoutes from './routes/upgrade';

Vue.use(Router);

const routes = [
    {
        path: '/',
        redirect: {
            name: "profile"
        }
    },
    {
        path: "/login",
        name: "login",
        component: LoginForm,
        meta: {
            guest: true
        }
    },
    {
        path: '/account',
        name: 'account',
        component: FullWidth,
        meta: {
            requiresAuth: true
        },
        props: {
            view: 'accountCenter',
            sidenavTitle: 'Account Center'
        },
        redirect: {
            name: 'profile'
        },
        children: [
            {
                path: 'profile',
                name: 'profile',
                title: 'accountCenter.profile.headline',
                icon: 'customers',
                components: {
                    sidecontent: ManageProfile
                },
            },
            {
                path: 'subscription',
                name: 'subscription',
                title: 'accountCenter.subscription.headline',
                icon: 'sitemap-submission',
                components: {
                    sidecontent: ManageSubscriptions
                },
                dispatches: [
                    'accountcenter/siteInfo'
                ],
            },
            {
                path: 'domains',
                name: 'domains',
                title: 'accountCenter.domains.headline',
                icon: 'home',
                components: {
                    sidecontent: ManageDomains
                },
                dispatches: [
                    'accountcenter/siteInfo'
                ],
             }
        ]
    },
    {
        path: '/upgrade',
        name: 'upgrade',
        title: 'Upgrade Flow',
        props: {
            view: 'upgradeFlow'
        },
        meta: {
            requiresAuth: true
        },
        component: Upgrade,
        children: UpgradeRoutes
    },
]

const router = new Router({
    mode: 'history',
    base: '/',
    routes
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const loggedin = $ss.get('jwtToken') ? true : false;

    if (requiresAuth && !loggedin) {
        next({
            path: '/login',
            params: { nextUrl: to.fullPath }
        });
    } else {
        if (!loggedin) {
            next();
        }
        else {
            next();
        }
    }
});

export default router;