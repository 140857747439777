import Vue from 'vue'
import App from './App.vue'
import i18n from '@/utils/lang'
import store from '@/store'
import router from './router'
import VueCookies from 'vue-cookies';
import UserLimits from '@/plugins/userlimits';
import '@monosolutions/monoiconfont';

import './scss/styles.scss'

Vue.config.productionTip = false
Vue.use(UserLimits);

Vue.use(VueCookies);

new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
