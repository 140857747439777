<template>
  <div class="checkout" :class="{ loading: isLoading }">
    <div class="paymentAndBilling">
      <h4 class="mds-heading-four">
        {{ $t("accountCenter.diy.checkout.card.headline") }}
      </h4>
      <div id="card-logos">
        <div
          v-for="card in allowedPaymentMethods"
          :key="card"
          :style="`background-image: url('${require(`../../assets/imgs/cards/${card}.png`)}');`"
          :class="{ 'selected-card': cardLogoSelected(card) }"
          class="payment-card-logo"
        />
      </div>
      <div class="cardDetails">
        <div class="cardNumberWrapper">
          <label for="card-number-element">{{
            $t("accountCenter.diy.checkout.card.numberLabel")
          }}</label>
          <div
            id="card-number-element"
            class="cardNumber"
            :class="{ hasError: cardHasError('card-number-element') }"
          />
        </div>
        <div class="twoColWrapper">
          <div class="cardExpiryWrapper">
            <label for="card-expiry-element">{{
              $t("accountCenter.diy.checkout.card.expiryLabel")
            }}</label>
            <div
              id="card-expiry-element"
              class="cardExpiryDate"
              :class="{ hasError: cardHasError('card-expiry-element') }"
            />
          </div>
          <div class="cardCvvWrapper">
            <label for="card-cvv-element"
              >CVV
              <div class="mds-tooltip-wrapper cvv-tooltip-wrapper">
                <cvv-tooltip
                  :text="$t('accountCenter.diy.checkout.card.cvvText')"
                  position="topCenter"
                /><mv-icon type="help" :size="16" class="hoverElement" /></div
            ></label>
            <div
              id="card-cvv-element"
              class="cardCvv"
              :class="{ hasError: cardHasError('card-cvv-element') }"
            />
          </div>
        </div>
        <div class="cardErrorsContainer mds-body-small">
          {{ stripeError.message }}
        </div>
      </div>
      <hr class="divider" />

      <h4 class="mds-heading-four">
        {{ $t("accountCenter.diy.checkout.billingAddress.headline") }}
      </h4>

      <div class="business-type-wrapper">
        <label class="mds-radio">
          <input
            class="mds-radio-wrapper"
            type="radio"
            name="businessType"
            :value="businessTypes[0].value"
            :checked="isBusiness"
            @change="changeBusinessType"
          />
          <span class="mds-radio-selector">
            <svg viewBox="0 0 24 24" width="16" height="16">
              <use href="#check" />
            </svg>
          </span>
          <span class="mds-radio-label">{{
            $t("accountCenter.diy.checkout.billingAddress.businessLabel")
          }}</span>
        </label>
        <label class="mds-radio">
          <input
            class="mds-radio-wrapper"
            type="radio"
            name="businessType"
            :value="businessTypes[1].value"
            :checked="!isBusiness"
            @change="changeBusinessType"
          />
          <span class="mds-radio-selector">
            <svg viewBox="0 0 24 24" width="16" height="16">
              <use href="#check" />
            </svg>
          </span>
          <span class="mds-radio-label">{{
            $t("accountCenter.diy.checkout.billingAddress.individualLabel")
          }}</span>
        </label>
      </div>

      <div class="billingInfo">
        <div class="twoColWrapper">
          <input-text
            index="firstName"
            label="accountCenter.diy.checkout.billingAddress.firstNameLabel"
            :required="true"
            :value="firstName"
            :disabled="false"
            class="twoColItem firstCol"
            :valid="formErrors.firstName === ''"
            :error-message="formErrors.firstName"
            @change="setFieldData"
          />
          <input-text
            index="lastName"
            label="accountCenter.diy.checkout.billingAddress.lastNameLabel"
            :required="true"
            :value="lastName"
            :disabled="false"
            class="twoColItem"
            :valid="formErrors.lastName === ''"
            :error-message="formErrors.lastName"
            @change="setFieldData"
          />
        </div>

        <input-text
          index="address"
          label="accountCenter.diy.checkout.billingAddress.addressLabel"
          :required="true"
          :value="address"
          :disabled="false"
          class="oneColItem"
          :valid="formErrors.address === ''"
          :error-message="formErrors.address"
          @change="setFieldData"
        />

        <div class="twoColWrapper">
          <input-text
            index="city"
            label="accountCenter.updatePaymentModal.billingAddress.cityLabel"
            :required="true"
            :value="city"
            :disabled="false"
            class="twoColItem firstCol"
            :valid="formErrors.city === ''"
            :error-message="formErrors.city"
            @change="setFieldData"
          />

          <input-text
            index="postalCode"
            label="accountCenter.updatePaymentModal.businessInformation.zip"
            :required="true"
            :value="postalCode"
            :disabled="false"
            class="twoColItem"
            :valid="formErrors.postalCode === ''"
            :error-message="formErrors.postalCode"
            @change="setFieldData"
          />
        </div>

        <div class="twoColWrapper">
          <input-phone-field
            index="phoneNumber"
            label="accountCenter.updatePaymentModal.businessInformation.phone"
            :required="true"
            :value="phoneNumber"
            :disabled="false"
            class="twoColItem firstCol"
            :valid="formErrors.phoneNumber === ''"
            :error-message="formErrors.phoneNumber"
            :default-country="defaultCountry"
            :only-countries="onlyCountries"
            @changePhoneNumber="setFieldData"
          />
          <input-text
            v-if="isBusiness"
            index="companyName"
            label="accountCenter.updatePaymentModal.businessInformation.companyName"
            :required="true"
            :value="companyName"
            :disabled="false"
            class="twoColItem"
            :valid="formErrors.companyName === ''"
            :error-message="formErrors.companyName"
            @change="setFieldData"
          />
        </div>

        <div v-if="isBusiness" class="twoColWrapper">
          <input-text
            index="vatId"
            label="accountCenter.diy.checkout.billingAddress.vatLabel"
            :required="true"
            :value="vatId"
            :disabled="false"
            class="twoColItem firstCol"
            :valid="formErrors.vatId === ''"
            :error-message="formErrors.vatId"
            @change="setFieldData"
          />
        </div>
      </div>
    </div>
    <hr v-if="isMobile" class="divider" />
    <div class="orderSummary">
      <order-summary
        :show-button="true"
        :show-terms="true"
        :show-cupon="true"
        :stripe="stripe"
        @submit="handleFormSubmission"
      />
    </div>

    <!-- Terms MODAL -->
    <div v-if="termsModalShow" class="modal-overlay terms-modal">
      <terms-modal :width="calculatedWidth" @termsApproved="registerDomain" @closeTermsModal="closeTermsModal" />
    </div> 

    <!-- Errors MODAL -->
    <div v-if="errorModal.show" class="modal-overlay error-modal">
      <errors-modal :title="errorModal.title" :body="errorModal.body" @closeErrorModal="closeErrorModal" />
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import Joi from "joi";
import { mapGetters } from "vuex";
import OrderSummary from "@/components/OrderSummary";
import TermsModal from "@/components/modals/TermsModal.vue";
import ErrorsModal from "@/components/modals/ErrorsModal.vue";
import UpgradeFlowMixin from "@/mixins/UpgradeFlowMixin";
import CvvTooltip from "@/components/CvvTooltip";
import {
  MvIcon,
  InputText,
  InputPhoneField,
} from "@monosolutions/vue-components";

export default {
  name: "diy-upgrade-checkout",
  components: {
    InputText,
    OrderSummary,
    TermsModal,
    CvvTooltip,
    MvIcon,
    InputPhoneField,
    ErrorsModal
  },
  mixins: [UpgradeFlowMixin],
  data() {
    return {
      stripe: null,
      cardNumber: null,
      cardExpiryDate: null,
      cardCvv: null,
      cardType: "",
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      postalCode: "",
      phoneNumber: "",
      companyName: "",
      vatId: null,
      formErrors: {
        cardNumber: "",
        cardExpiry: "",
        cardCvv: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        postalCode: "",
        phoneNumber: "",
        companyName: "",
        vatId: "",
      },
      businessTypes: [
        {
          value: "company",
          label: "accountCenter.diy.checkout.billingAddress.businessLabel",
        },
        {
          value: "individual",
          label: "accountCenter.diy.checkout.billingAddress.individualLabel",
        },
      ],
      businessType: "company",
      showButton: true,
      cardUpdate: false,
      stripeCardNumber: null,
      stripeError: {
        code: "",
        message: "",
      },
      stripePaymentIntent: undefined,
      isLoading: false,
      couponPromoCode: "",
      termsModalShow: false,
      errorModal: {
        show: false,
        title: '',
        body: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      accountCenterSettings: 'accountcenter/accountCenterSettings',
      domainName: "upgradeflow/domainName",
      checkoutItems: "upgradeflow/checkoutItems",
      customerInfo: "upgradeflow/customerInfo",
      domainRegistrationSkipped: "upgradeflow/domainRegistrationSkipped",
    }),
    isMobile() {
      return this.$mq === "mobile";
    },
    calculatedWidth() {
      return this.$mq === "mobile" ? "100vw" : 800;
    },
    allowedPaymentMethods() {
      return ["visa", "mastercard", "amex"];
    },
    isBusiness() {
      return this.businessType === "company";
    },
    priceFormattingLocale() {
      return this.accountCenterSettings.priceFormattingLocale;
    },
    fieldsAreValid() {
      const fields = {
        firstName: this.firstName,
        lastName: this.lastName,
        address: this.address,
        city: this.city,
        postalCode: this.postalCode,
        phoneNumber: this.phoneNumber,
      };

      if (this.isBusiness) {
        fields.vatId = this.vatId;
        fields.companyName = this.companyName;
      }

      const validationResult = Joi.object().keys(this.fieldsValidationSchema).validate(fields);
      return validationResult.error ? false : true;
    },
    fieldsValidationSchema() /* istanbul ignore next */ {
      const validationSchema = {
        firstName: this.nameValidationSchema,
        lastName: this.nameValidationSchema,
        address: this.addressValidationSchema,
        city: this.cityValidationSchema,
        postalCode: this.postalCodeValidationSchema,
        phoneNumber: this.phoneNumberValidationSchema,
      };

      if (this.isBusiness) {
        validationSchema.companyName = this.companyValidationSchema;
        validationSchema.vatId = this.vatIdValidationSchema;
      }

      return validationSchema;
    },
    nameValidationSchema() /* istanbul ignore next */ {
      // add .label to use for error messages later
      return Joi.string().empty().required().min(2);
    },
    addressValidationSchema() /* istanbul ignore next */ {
      return Joi.string().empty().required().min(4);
    },
    cityValidationSchema() /* istanbul ignore next */ {
      return Joi.string().empty().required().min(2);
    },
    postalCodeValidationSchema() /* istanbul ignore next */ {
      return Joi.string()
        .regex(/^[0-9]+$/)
        .length(4)
        .required();
    },
    phoneNumberValidationSchema() /* istanbul ignore next */ {
      return Joi.string().empty().required();
    },
    companyValidationSchema() /* istanbul ignore next */ {
      return Joi.string().empty().required().min(3);
    },
    vatIdValidationSchema() /* istanbul ignore next */ {
      return Joi.string()
        .regex(/^[0-9]+$/)
        .length(8)
        .required();
    },
    defaultCountry() {
      return this.isDkDomain(this.domainName) ? "DK" : "";
    },
    onlyCountries() {
      return this.isDkDomain(this.domainName) ? ["DK"] : [];
    },
  },
  async mounted() {
    this.siteInfo = await this.$store.dispatch("accountcenter/getSiteInfo");
    this.initializeStripe();
    this.fillFieldsWithPreviousValues();
  },
  methods: {
    async initializeStripe() {
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY || this.accountCenterSettings.stripe.publishableApiKey);
      this.stripe = stripe;
      this.initializeCardElements();
    },
    cardLogoSelected(brand) {
      return brand === this.cardType;
    },
    changeBusinessType(evt) {
      this.businessType = evt.target.value;
    },

    toggleLoading(force = false) {
      if (force) {
        this.isLoading = force;
        return;
      }
      this.isLoading = !this.isLoading;
    },

    async handleFormSubmission(data) {
      if (data.couponPromoCode) this.couponPromoCode = data.couponPromoCode;

      if (!this.fieldsAreValid) return null;

      const customerInfo = {
        ...{
          firstName: this.firstName,
          lastName: this.lastName,
          address: this.address,
          city: this.city,
          postalCode: this.postalCode,
          phoneNumber: this.phoneNumber,
          businessType: this.businessType,
        },
        ...(this.isBusiness && { companyName: this.companyName }),
        ...(this.isBusiness && { vatId: this.vatId }),
      };

      this.$store.dispatch("upgradeflow/setCustomerInfo", customerInfo);

      if (this.domainRegistrationSkipped) {
        this.toggleLoading(true);
        const processPaymentResult = await this.processPayment();

        if (processPaymentResult === true) {
          this.$router.push({ path: "/upgrade/confirmation" });
        }

        this.toggleLoading();
        return;
      }

      if (this.isDkDomain(this.domainName)) {
        this.termsModalShow = true;
      } else {
        await this.registerDomain();
      }
    },

    async registerDomain() {
      this.toggleLoading(true);

      const domainIsAvailable = await this.checkDomainIsAvailable();

      if (!domainIsAvailable) {
        this.errorModal.show = true;
        this.errorModal.title = 'accountCenter.domainManagement.findDomain.error';
        this.errorModal.body = 'accountCenter.domainManagement.findDomain.taken';
        this.toggleLoading();
        return domainIsAvailable;
      }

      if (this.isDkDomain(this.domainName)) {
        let contactCreated = false;

        contactCreated = await this.callCreateContactApi();

        if (!contactCreated) {
          this.toggleLoading();
          return false;
        }
      }

      const processPaymentResult = await this.processPayment();

      let registerSuccess = false;

      if (processPaymentResult === true) {
        registerSuccess = await this.callRegisterDomainApi();

        if (registerSuccess) {
          this.$router.push({ path: "/upgrade/confirmation" });
        } else {
          // Make the stripe refund when available on Account Center API.
          // At the moment the refund will be handled via support team
          console.error("Domain registration failed");
          this.errorModal.show = true;
          this.errorModal.title = 'accountCenter.domainManagement.findDomain.error';
          this.errorModal.body = 'accountCenter.domainManagement.findDomain.support';
        }
      }

      this.toggleLoading();
      return registerSuccess;
    },

    async callCreateContactApi() {
      let createContactSuccess = false;
      const apiData = this.getPreparedApiData();

      try {
        const createContactResult = await this.$store.dispatch("accountcenter/createContactDK", {
          contactSet: apiData,
          tldData: apiData.tldData
        });

        if (createContactResult.status === 200) {
          createContactSuccess = true;
        } else {
          console.error(createContactSuccess);
          this.errorModal.show = true;
          this.errorModal.title = 'accountCenter.domainManagement.findDomain.error';
          this.errorModal.body = 'accountCenter.diy.checkout.errors.contactCreation';
        }
      } catch (error) {
        this.errorModal.show = true;
        this.errorModal.title = 'accountCenter.domainManagement.findDomain.error';
        this.errorModal.body = 'general.error';
      }

      return createContactSuccess;
    },

    async callRegisterDomainApi() {
      let registerSuccess = false;
      const apiData = this.getPreparedApiData();

      try {
        const registerDomainResult = await this.$store.dispatch("accountcenter/addSiteDomain", {
          domain: apiData.domain
        });

        if (registerDomainResult.status === 200) {
          registerSuccess = true;
        } else {
          this.errorModal.show = true;
          this.errorModal.title = 'accountCenter.domainManagement.findDomain.registrationError';
          this.errorModal.body = 'accountCenter.domainManagement.findDomain.support';
        }
      } catch (error) {
        this.errorModal.show = true;
        this.errorModal.title = 'accountCenter.domainManagement.findDomain.registrationError';
        this.errorModal.body = 'general.error';
      }

      return registerSuccess;
    },

    getPreparedApiData() {
      return {
        first_name: this.firstName,
        last_name: this.lastName,
        address1: this.address,
        address2: "",
        address3: "",
        postal_code: this.postalCode,
        city: this.city,
        phone: this.phoneNumber,
        email: this.siteInfo.email,
        // Is country mandatory? On account center it is, so we alway have to use DK unless it's defined otherwise
        country: "DK",
        org_name: this.companyName || "",
        org_no: this.vatId || "", // Leave it here as well in case that's needed by different non dk providers
        tldData: {
          usertype: this.businessType,
          org_no: this.vatId || "",
        },
        domain: this.domainName,
        testMode: this.accountCenterSettings.domain.registrationTestMode,
        attachDomain: this.checkoutItems.plan.key === "professional" ? 1 : 0, //attaches domain and add cookiebot subscription
      };
    },

    async checkDomainIsAvailable() {
      let domainIsAvailable = false;
      try {
        const domainAvailability = await this.$store.dispatch("accountcenter/checkDomainIsAvailable", {
            domain: this.domainName
        });

        if (domainAvailability.status === 200 && domainAvailability.data.available) {
          domainIsAvailable = true;
        }
      } catch (error) {
        this.errorModal.show = true;
        this.errorModal.title = 'accountCenter.domainManagement.findDomain.error';
        this.errorModal.body = 'general.error';
      }

      return domainIsAvailable;
    },

    async processPayment() {
      let paymentMethod = false;
      const paymentMethodResult = await this.stripeCreatePaymentMethod();

      if (paymentMethodResult) {
        paymentMethod = paymentMethodResult.paymentMethod.id;

        const subscriptionResult = await this.createSubscription(paymentMethod);

        if (subscriptionResult) {
          return true;
        }
      } else {
        this.termsModalShow = false;
        this.errorModal.show = true;
        this.errorModal.title = 'accountCenter.diy.checkout.errors.payment.title';
        this.errorModal.body = 'accountCenter.diy.checkout.errors.payment.body';
        return false;
      }
    },
    initializeCardElements() {
      const elements = this.stripe.elements({ locale: "da" });

      const cardNumberElement = elements.create("cardNumber", {
        placeholder: "1111 2222 3333 4444",
        classes: {
          base: "cardNumber",
          empty: "cardNumber",
        },
      });
      const cardExpiryElement = elements.create("cardExpiry", {
        placeholder: "MM/YY",
      });
      const cardCvcElement = elements.create("cardCvc", {
        placeholder: "123",
      });

      cardNumberElement.mount("#card-number-element");
      cardExpiryElement.mount("#card-expiry-element");
      cardCvcElement.mount("#card-cvv-element");

      this.stripeCardNumber = cardNumberElement;

      cardNumberElement.on("change", (event) => {
        this.formErrors.cardNumber = event.error ? event.error.message : "";
        this.cardType = event.brand;
      });
    },
    setFieldData(payload) {
      let { value } = payload.data;

      if (payload.data.countryCode && payload.data.countryCode === "45") {
        const re = new RegExp(`\\+${payload.data.countryCode}\\.?`, "g");
        value = value.replace(re, `+${payload.data.countryCode}.`);
      }

      const result = this.fieldsValidationSchema[payload.index].validate(value);

      // error.context.label can be used to inject the field's label
      // in the error message
      let errorMap;
      if (result.error) {
        errorMap = {
          "any.empty": this.$t("accountCenter.diy.checkout.errors.empty"),
          "any.required": this.$t("accountCenter.diy.checkout.errors.required"),
          "string.min": this.$t("accountCenter.diy.checkout.errors.min", {
            number: result.error.details[0].context.limit,
          }),
          "string.length": this.$t("accountCenter.diy.checkout.errors.min", {
            number: result.error.details[0].context.limit,
          }),
          "string.max": this.$t("accountCenter.diy.checkout.errors.max", {
            number: result.error.details[0].context.limit,
          }),
          "string.regex.base":
            payload.index === "phoneNumber" && this.isDkDomain(this.domainName)
              ? result.error.message
              : this.$t("accountCenter.diy.checkout.errors.notANumber"),
          "string.base": this.$t(
            "accountCenter.diy.checkout.errors.notAString"
          ),
        };
      }
      this.formErrors[payload.index] = result.error
        ? errorMap[result.error.details[0].type]
        : "";
      this.$set(this, payload.index, value);
      return payload;
    },
    fillFieldsWithPreviousValues() {
      if (this.customerInfo) {
        const customerInfo = Object.assign({}, this.customerInfo);

        for (const field in customerInfo) {
          this[field] = customerInfo[field];
        }
      }
    },
    async stripeCreatePaymentMethod() {
      const result = await this.stripe.createPaymentMethod({
        type: "card",
        card: this.stripeCardNumber,
        billing_details: {
          email: this.siteInfo.email,
          name: `${this.firstName} ${this.lastName}`,
          phone: this.phoneNumber,
          address: {
            line1: this.address,
            postal_code: this.postalCode,
            city: this.city,
          }
        },
      });

      if (result.error) {
        this.setStripeError(result.error);
        return false;
      }

      return result;
    },
    async createSubscription(payment_method) {
      const customerData = {
        email: this.siteInfo.email,
        preferred_locales: ["da", this.priceFormattingLocale],
        plan: this.checkoutItems.plan.key,
        billingDetails: {
          name: `${this.firstName} ${this.lastName}`,
          address: {
            line1: this.address,
            postal_code: this.postalCode,
            city: this.city,
            country: "DK",
          },
        },
      };

      if (this.isBusiness) {
        customerData.billingDetails.invoice_settings = {};
        customerData.billingDetails.tax_id_data = [
          {
            type: "eu_vat",
            value: `DK${this.vatId}`,
          },
        ];
        customerData.billingDetails.companyName = this.companyName;
      }

      if (payment_method) {
        customerData.payment_method = payment_method;
      }

      if (this.couponPromoCode)
        customerData.couponPromoCode = this.couponPromoCode;

      try {
        const response = await this.$store.dispatch("accountcenter/createSubscription", customerData);

        const {
          client_secret,
          payment_intent_status,
          latest_invoice_status,
          subscription_status
        } = response.data;

        if ((payment_intent_status === "succeeded" || latest_invoice_status === "paid") && subscription_status === "active") {
          return true;
        } else if (payment_intent_status === 'requires_action') {
          const cardPaymentConfirmation = await this.stripe.confirmCardPayment(client_secret, {
              payment_method
          });
          if (cardPaymentConfirmation.paymentIntent?.status) {
              return true;
          } else if (cardPaymentConfirmation.error) {
              this.errorModal.show = true;
              this.errorModal.title = 'accountCenter.diy.checkout.errors.confirmCard.title';
              this.errorModal.body = 'accountCenter.diy.checkout.errors.confirmCard.body';
              return false;
          }
        }
      } catch (error) {
        console.error("error caught on createSubscription", error);
      }
    },
    setStripeError(errorObj) {
      this.stripeError.message = errorObj.message;
      this.stripeError.code = errorObj.code;
    },
    cardHasError(fieldId) {
      const cardNumberErrors = [
        "incorrect_number",
        "invalid_number",
        "incomplete_number",
      ];
      const cardExpiryErrors = [
        "invalid_expiry_month",
        "invalid_expiry_year",
        "incomplete_expiry",
      ];
      const cardCvvErrors = ["invalid_cvc", "incorrect_cvc", "incomplete_cvc"];
      let hasError = false;

      switch (fieldId) {
        case "card-number-element":
          if (cardNumberErrors.includes(this.stripeError.code)) {
            hasError = true;
          }
          break;
        case "card-expiry-element":
          if (cardExpiryErrors.includes(this.stripeError.code)) {
            hasError = true;
          }
          break;
        case "card-cvv-element":
          if (cardCvvErrors.includes(this.stripeError.code)) {
            hasError = true;
          }
          break;
      }

      return hasError;
    },
    closeTermsModal() {
      this.termsModalShow = false;
    },
    closeErrorModal() {
      this.errorModal.show = false;
    }
  },
};
</script>
