<template>
    <mv-modal
        title="accountCenter.domainManagement.slideTerms.headline"
        firstButton
        secondButton
        firstButtonText="accountCenter.general.cancel"
        secondButtonText="accountCenter.domainManagement.slideTerms.diy.agree"
        firstButtonColor="grey"
        @first-button-clicked="$emit('closeTermsModal')"
        @second-button-clicked="save"
        @modal-close-clicked="$emit('closeTermsModal')"
    >
    <div slot="mv-modal-content">
        <!-- Warning / Validation -->
        <div class="termsModal pageOne-content">
            <p class="overlay-preamble">
                {{ $t('accountCenter.domainManagement.slideTerms.preamble') }}
            </p>
            <p>
                <strong>{{ $t('accountCenter.domainManagement.slideTerms.domainName') }}</strong><br>{{ domainName }}
            </p>
            <p class="prewrap">
                <strong>{{ $t('accountCenter.domainManagement.slideTerms.registrant') }}</strong><br>{{ registrant }}
            </p>
            <p class="prewrap">
                <strong>{{ $t('accountCenter.domainManagement.slideTerms.administrator') }}</strong><br>{{ administrator }}
            </p>
            <p class="terms">
                {{ $t('accountCenter.domainManagement.slideTerms.diy.terms', {company: company}) }}
            </p>

            <ul class="links">
                <li><a :href="termsLink['en']" target="_blank">{{ $t('accountCenter.domainManagement.slideTerms.linkTerms') }}</a></li>
                <li><a :href="privacyLink['en']" target="_blank">{{ $t('accountCenter.domainManagement.slideTerms.linkPrivacy') }}</a></li>
                <li><a :href="aboutLink['en']" target="_blank">{{ $t('accountCenter.domainManagement.slideTerms.linkAbout') }}</a></li>
            </ul>

            <input-checkbox-field :checked="termAcceptance" :label="'accountCenter.domainManagement.slideTerms.acceptance'" index="acceptance" class="termsAcceptance" @change="toggleTermAcceptance" />
            <p v-if="!termAcceptance && termAcceptance !== null" class="terms-not-accepted">
                {{ $t('accountCenter.domainManagement.slideTerms.diy.termsNotAccepted') }}
            </p>
        </div>
    </div>
    </mv-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { MvModal, InputCheckboxField } from '@monosolutions/vue-components';

export default {
    name: 'terms-overlay',
    components: { MvModal, InputCheckboxField },
    props: {
        name: {
            type: String,
            'default': 'terms'
        },
        width: {
            type: [ Number, String ],
            'default': 800
        },
        height: {
            type: [ Number, String ],
            'default': 'auto'
        },
        title: {
            type: String,
            'default': 'accountCenter.domainManagement.slideTerms.headline'
        }
    },
    data() {
        return {
            company: 'Mono Solutions ApS',
            administrator: 'DK Hostmaster A/S\nØrestads Boulevard 108, 11th floor\nDK-2300 Copenhagen',
            termAcceptance: null,
            termsLink: {
                en: 'https://www.dk-hostmaster.dk/en/terms',
                da: 'https://www.dk-hostmaster.dk/vilkar'
            },
            privacyLink: {
                en: 'https://www.dk-hostmaster.dk/en/privacy-policy',
                da: 'https://www.dk-hostmaster.dk/da/persondatapolitik'
            },
            aboutLink: {
                en: 'https://www.dk-hostmaster.dk/en',
                da: 'https://www.dk-hostmaster.dk'
            },
        };
    },
    computed: {
        ...mapGetters({
            accountCenterSettings: 'accountcenter/accountCenterSettings',
            domainName: 'upgradeflow/domainName',
            customerInfo: 'upgradeflow/customerInfo',
            accountInfo: 'accountcenter/accountInfo'
        }),
        registrant() {
            const { firstName, lastName, address, postalCode, city } = this.customerInfo;

            return `${firstName} ${lastName}\n${address}\n${postalCode} ${city}, Danmark`;
        },
        isMobile() {
            return this.$mq === 'mobile';
        }
    },
    methods: {
        toggleTermAcceptance(payload) {
            this.termAcceptance = payload.data.checked;
        },
        save() {
            if (this.termAcceptance) {
                this.$emit('termsApproved');
            } else {
                this.termAcceptance = false;
            }
        }
    }
};
</script>
