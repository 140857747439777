
export default {
    data() {
        return {
            heapTrackingAdded: false
        };
    },
    computed: {
        addHeapTracking() {
            return process.env.VUE_APP_HEAP_APPID && window.heap;
        }
    },
    methods: {
        loadHeap() {
            if (this.addHeapTracking) {
                //Load heap
                window.heap.load(process.env.VUE_APP_HEAP_APPID);
            }
        }
    }
};