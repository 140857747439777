/**
 * The file enables `@/store/index.js` to import all vuex modules
 * in a one-shot manner. There should not be any reason to edit this file.
 */

/*eslint-disable */
let files;
files = require.context('.', false, /\.js$/);

const modules = {};
files.keys().forEach(key => {
    if (/\/index\.js$/.test(key)) return;
    let fileName = key.replace(/^.*[\/\\]([^\/\\]+)\.js$/g, "$1"),
        file = files(key).default;

    //Always make the modules namespaced
    if (file) {
        modules[fileName] = file;
        modules[fileName].namespaced = true;
    }

});

export default modules;