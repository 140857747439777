<template>
    <div class="confirmation">
        <div class="box">
            <h4 class="headline">
                {{ $t('accountCenter.domainManagement.confirmation.diy.headline') }} &#x1F64C;
            </h4>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="preamble" v-html="$t(`accountCenter.domainManagement.confirmation.diy.preamble.${messageType}`)" />

            <div class="buttons">
                <mv-button
                    :text=" $t('accountCenter.domainManagement.confirmation.diy.goToDashboard')"
                    class="expand"
                    @click="goToDashboard"
                />
            </div>
        </div>

        <hr v-if="isMobile" class="divider">

        <div class="orderSummary">
            <order-summary :show-button="false" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UpgradeFlowMixin from '@/mixins/UpgradeFlowMixin';
import OrderSummary from '@/components/OrderSummary';
import { MvButton } from '@monosolutions/vue-components';

export default {
    name: 'diy-confirmation',
    components: { OrderSummary, MvButton },
    mixins: [ UpgradeFlowMixin ],
    computed: {
        ...mapGetters({
            accountCenterSettings: 'accountcenter/accountCenterSettings',
            domainName: 'upgradeflow/domainName',
            accountStatus: 'accountcenter/accountStatus',
        }),
        isMobile() {
            return this.$mq === 'mobile';
        },
        messageType() {
            if (!this.domainName || this.domainName === '') {
                return 'domainNotChoosen';
            }

            return (this.domainName.substr(-3) === '.dk') ? 'DKdomain' : 'nonDKdomain';
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.addHeapNewPlan();
        });
    },
    methods: {
        goToDashboard() {
            this.$router.push({ path: '/' });
        },
        async addHeapNewPlan() {
            await this.$store.dispatch('accountcenter/getAccountStatus');
            const currentPlan = this.accountCenterSettings.plans.filter(plan => plan.key === this.accountStatus.status);

            if (window.heap) {
                window.heap.addUserProperties({
                    subscriptionType: currentPlan[0].halApiId
                });
            }
        },
    }
};
</script>
