<template>
    <mv-modal
        :title="title"
        :description="body"
        type="illustration"
        illustrationType="icon"
        illustrationIconType="alert-fill"
        illustrationIconColor="#D65B3F"
        illustrationIconBackgroundColor="#F5D0BE"
        firstButton
        :secondButton="false"
        firstButtonText="accountCenter.general.ok"
        firstButtonColor="red"
        @first-button-clicked="$emit('closeErrorModal')"
        @modal-close-clicked="$emit('closeErrorModal')"
    />
</template>

<script>
import { MvModal } from '@monosolutions/vue-components';

export default {
    name: 'errors-modal',
    components: { MvModal },
    props: {
        title: {
            type: String,
            'default': ''
        },
        body: {
            type: String,
            'default': ''
        }
    }
};
</script>
