<template>
  <mv-modal
    title="accountCenter.updatePaymentModal.headline"
    firstButton
    secondButton
    firstButtonText="accountCenter.general.cancel"
    secondButtonText="accountCenter.general.update"
    firstButtonColor="grey"
    :secondButtonDisabled="secondButtonDisabled"
    @first-button-clicked="$emit('closeUpdatePaymentModal')"
    @second-button-clicked="savePaymentMethod"
    @modal-close-clicked="$emit('closeUpdatePaymentModal')"
  >
    <div slot="mv-modal-content" :class="{ loading: isLoading }">
      <div class="paymentModal">
        <div id="card-logos">
          <div
            v-for="card in allowedPaymentMethods"
            :key="card"
            :style="`background-image: url('${require(`../../assets/imgs/cards/${card}.png`)}');`"
            :class="{ 'selected-card': cardLogoSelected(card) }"
            class="payment-card-logo"
          />
        </div>
        <div class="cardDetails">
          <div class="cardNumberWrapper">
            <label for="card-number-element">{{ $t('accountCenter.updatePaymentModal.numberLabel') }}</label>
            <div id="card-number-element" class="cardNumber" :class="{'hasError': cardHasError('card-number-element')}" />
          </div>
          <div class="twoColWrapper">
            <div class="cardExpiryWrapper">
              <label for="card-expiry-element">{{ $t('accountCenter.updatePaymentModal.expiryLabel') }}</label>
              <div id="card-expiry-element" class="cardExpiryDate" :class="{'hasError': cardHasError('card-expiry-element')}" />
            </div>
            <div class="cardCvvWrapper">
              <label for="card-cvv-element">CVV</label>
              <div id="card-cvv-element" class="cardCvv" :class="{'hasError': cardHasError('card-cvv-element')}" />
            </div>
          </div>
          <div class="cardErrorsContainer mds-body-small"></div>
        </div>
        <hr class="divider" />
        <h4 class="mds-heading-four">{{ $t('accountCenter.updatePaymentModal.billingAddress.headline') }}</h4>

        <div class="business-type-wrapper">
          <label class="mds-radio">
            <input
              class="mds-radio-wrapper"
              type="radio"
              name="businessType"
              :value="businessTypes[0].value"
              :checked="isBusiness"
              @change="changeBusinessType"
            />
            <span class="mds-radio-selector">
              <svg viewBox="0 0 24 24" width="16" height="16">
                <use href="#check" />
              </svg>
            </span>
            <span class="mds-radio-label">{{ $t('accountCenter.updatePaymentModal.billingAddress.businessLabel') }}</span>
          </label>
          <label class="mds-radio">
            <input
              class="mds-radio-wrapper"
              type="radio"
              name="businessType"
              :value="businessTypes[1].value"
              :checked="!isBusiness"
              @change="changeBusinessType"
            />
            <span class="mds-radio-selector">
              <svg viewBox="0 0 24 24" width="16" height="16">
                <use href="#check" />
              </svg>
            </span>
            <span class="mds-radio-label">{{ $t('accountCenter.updatePaymentModal.billingAddress.individualLabel') }}</span>
          </label>
        </div>

        <div class="billingInfo">
          <div class="twoColWrapper">
            <input-text
              index="firstName"
              label="accountCenter.updatePaymentModal.billingAddress.firstNameLabel"
              :required="true"
              :value="firstName"
              :disabled="false"
              class="twoColItem firstCol"
              :valid="formErrors.firstName === ''"
              :error-message="formErrors.firstName"
              @change="setFieldData"
            />
            <input-text
              index="lastName"
              label="accountCenter.updatePaymentModal.billingAddress.lastNameLabel"
              :required="true"
              :value="lastName"
              :disabled="false"
              class="twoColItem"
              :valid="formErrors.lastName === ''"
              :error-message="formErrors.lastName"
              @change="setFieldData"
            />
          </div>

          <input-text
            index="address"
            label="accountCenter.updatePaymentModal.billingAddress.addressLabel"
            :required="true"
            :value="address"
            :disabled="false"
            class="oneColItem"
            :valid="formErrors.address === ''"
            :error-message="formErrors.address"
            @change="setFieldData"
          />

          <div class="twoColWrapper">
            <input-text
              index="city"
              label="accountCenter.updatePaymentModal.billingAddress.cityLabel"
              :required="true"
              :value="city"
              :disabled="false"
              class="twoColItem firstCol"
              :valid="formErrors.city === ''"
              :error-message="formErrors.city"
              @change="setFieldData"
            />

            <input-text
              index="postalCode"
              label="accountCenter.updatePaymentModal.businessInformation.zip"
              :required="true"
              :value="postalCode"
              :disabled="false"
              class="twoColItem"
              :valid="formErrors.postalCode === ''"
              :error-message="formErrors.postalCode"
              @change="setFieldData"
            />
          </div>

          <div class="twoColWrapper">
            <input-phone-field
              index="phoneNumber"
              label="accountCenter.updatePaymentModal.businessInformation.phone"
              :required="true"
              :value="phoneNumber"
              :disabled="false"
              class="twoColItem firstCol"
              :valid="formErrors.phoneNumber === ''"
              :error-message="formErrors.phoneNumber"
              :default-country="defaultCountry"
              :only-countries="onlyCountries"
              @changePhoneNumber="setFieldData"
            />
            <input-text
              v-if="isBusiness"
              index="companyName"
              label="accountCenter.updatePaymentModal.businessInformation.companyName"
              :required="true"
              :value="companyName"
              :disabled="false"
              class="twoColItem"
              :valid="formErrors.companyName === ''"
              :error-message="formErrors.companyName"
              @change="setFieldData"
            />
          </div>

          <div v-if="isBusiness" class="twoColWrapper">
            <input-text
              index="vatId"
              label="accountCenter.updatePaymentModal.billingAddress.vatLabel"
              :required="true"
              :value="vatId"
              :disabled="false"
              class="twoColItem firstCol"
              :valid="formErrors.vatId === ''"
              :error-message="formErrors.vatId"
              @change="setFieldData"
            />
          </div>
        </div>
      </div>
    </div>
  </mv-modal>
</template>

<script>
import {
  MvModal,
  InputText,
  InputPhoneField,
} from "@monosolutions/vue-components";
import Joi from "joi";

export default {
  name: "PaymentModal",
  components: { MvModal, InputText, InputPhoneField },
  props: {
    stripe: {
      type: Object,
      'default': null
    },
    email: {
      type: String,
      'default': ''
    },
  },
  data() {
    return {
      cardType: "",
      businessTypes: [
        {
          value: "company",
          label: "accountCenter.updatePaymentModal.billingAddress.businessLabel",
        },
        {
          value: "individual",
          label: "accountCenter.updatePaymentModal.billingAddress.individualLabel",
        },
      ],
      businessType: "company",
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      postalCode: "",
      phoneNumber: "",
      companyName: "",
      vatId: null,
      formErrors: {
        cardNumber: "",
        cardExpiry: "",
        cardCvv: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        postalCode: "",
        phoneNumber: "",
        companyName: "",
        vatId: "",
      },
      stripeCardNumber: null,
      stripeError: {
        code: '',
        message: ''
      },
      secondButtonDisabled: false,
      isLoading: true
    };
  },
  computed: {
    allowedPaymentMethods() {
      return ["visa", "mastercard", "amex"];
    },
    isBusiness() {
      return this.businessType === "company";
    },
    defaultCountry() {
      return "DK";
    },
    onlyCountries() {
      return [ "DK" ];
    },
    fieldsAreValid() {
      const fields = {
        firstName: this.firstName,
        lastName: this.lastName,
        address: this.address,
        city: this.city,
        postalCode: this.postalCode,
        phoneNumber: this.phoneNumber,
      };

      if (this.isBusiness) {
        fields.vatId = this.vatId;
        fields.companyName = this.companyName;
      }

      const validationResult = Joi.object().keys(this.fieldsValidationSchema).validate(fields);
      return validationResult.error ? false : true;
    },
    fieldsValidationSchema() /* istanbul ignore next */ {
      const validationSchema = {
        firstName: this.nameValidationSchema,
        lastName: this.nameValidationSchema,
        address: this.addressValidationSchema,
        city: this.cityValidationSchema,
        postalCode: this.postalCodeValidationSchema,
        phoneNumber: this.phoneNumberValidationSchema,
      };

      if (this.isBusiness) {
        validationSchema.companyName = this.companyValidationSchema;
        validationSchema.vatId = this.vatIdValidationSchema;
      }

      return validationSchema;
    },
    nameValidationSchema() /* istanbul ignore next */ {
      // add .label to use for error messages later
      return Joi.string().regex(/^[a-zA-Z]+$/).empty().required().min(2);
    },
    addressValidationSchema() /* istanbul ignore next */ {
      return Joi.string().empty().required().min(4);
    },
    cityValidationSchema() /* istanbul ignore next */ {
      return Joi.string().empty().required().min(2);
    },
    postalCodeValidationSchema() /* istanbul ignore next */ {
      return Joi.string()
        .regex(/^[0-9]+$/)
        .length(4)
        .required();
    },
    phoneNumberValidationSchema() /* istanbul ignore next */ {
      return Joi.string().empty().required();
    },
    companyValidationSchema() /* istanbul ignore next */ {
      return Joi.string().empty().min(3);
    },
    vatIdValidationSchema() /* istanbul ignore next */ {
      return Joi.string()
        .regex(/^[0-9]+$/)
        .length(8)
        .required();
    },
  },
  async mounted() {
    this.initializeCardElements();
    this.accountStatus = await this.$store.dispatch("accountcenter/getAccountStatus");
    this.fillFieldsWithPreviousValues();
    this.isLoading = false;
  },
  methods: {
    initializeCardElements() {
      const elements = this.stripe.elements({ locale: 'da' });

      const cardNumberElement = elements.create('cardNumber', {
        placeholder: '1111 2222 3333 4444',
        classes: {
          base: 'cardNumber',
          empty: 'cardNumber'
        }
      });
      const cardExpiryElement = elements.create('cardExpiry', {
        placeholder: 'MM/YY'
      });
      const cardCvcElement = elements.create('cardCvc', {
        placeholder: '123'
      });

      cardNumberElement.mount('#card-number-element');
      cardExpiryElement.mount('#card-expiry-element');
      cardCvcElement.mount('#card-cvv-element');

      this.stripeCardNumber = cardNumberElement;

      cardNumberElement.on('change', event => {
        this.formErrors.cardNumber = event.error ? event.error.message : '';
        this.cardType = event.brand;
      });
    },
    async stripeCreatePaymentMethod() {
      const result = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.stripeCardNumber,
        billing_details: {
          email: this.email,
          name: `${this.firstName} ${this.lastName}`,
          phone: this.phoneNumber,
          address: {
            line1: this.address,
            postal_code: this.postalCode,
            city: this.city
          }
        }
      });

      if (result.error) {
        this.setStripeError(result.error);
        return false;
      }

      return result;
    },
    async savePaymentMethod() {
      if (!this.fieldsAreValid) return null;
      this.secondButtonDisabled = true;
      if (this.isBusiness){
        await this.updateTaxDetails();
      }
      const paymentMethodResult = await this.stripeCreatePaymentMethod();
      const payment_method = paymentMethodResult.paymentMethod.id;
      await this.$store.dispatch("accountcenter/updatePaymentCard", {
        payment_method
      });
      this.$emit("savePaymentMethod");
    },
    async updateTaxDetails() {
      await this.$store.dispatch("accountcenter/updateTaxDetails", {
        tax_value: `DK${this.vatId}`,
        company_name: this.companyName
      });
    },
    cardLogoSelected(brand) {
      return brand === this.cardType;
    },
    changeBusinessType(evt) {
      this.businessType = evt.target.value;
    },
    setFieldData(payload) {
      let { value } = payload.data;

      if (payload.data.countryCode && payload.data.countryCode === "45") {
        const re = new RegExp(`\\+${payload.data.countryCode}\\.?`, "g");
        value = value.replace(re, `+${payload.data.countryCode}.`);
      }

      const result = this.fieldsValidationSchema[payload.index].validate(value);

      // error.context.label can be used to inject the field's label
      // in the error message
      let errorMap;
      if (result.error) {
        errorMap = {
          "any.empty": this.$t("accountCenter.updatePaymentModal.errors.empty"),
          "any.required": this.$t("accountCenter.updatePaymentModal.errors.required"),
          "string.min": this.$t("accountCenter.updatePaymentModal.errors.min", {
            number: result.error.details[0].context.limit,
          }),
          "string.length": this.$t("accountCenter.updatePaymentModal.errors.min", {
            number: result.error.details[0].context.limit,
          }),
          "string.max": this.$t("accountCenter.updatePaymentModal.errors.max", {
            number: result.error.details[0].context.limit,
          }),
          "string.regex.base": this.$t("accountCenter.updatePaymentModal.errors.notANumber"),
          "string.base": this.$t(
            "accountCenter.updatePaymentModal.errors.notAString"
          ),
        };
      }
      this.formErrors[payload.index] = result.error
        ? errorMap[result.error.details[0].type]
        : "";
      this.$set(this, payload.index, value);
      return payload;
    },
    setStripeError(errorObj) {
      this.stripeError.message = errorObj.message;
      this.stripeError.code = errorObj.code;
    },
    cardHasError(fieldId) {
      const cardNumberErrors = [ 'incorrect_number', 'invalid_number', 'incomplete_number' ];
      const cardExpiryErrors = [ 'invalid_expiry_month', 'invalid_expiry_year', 'incomplete_expiry' ];
      const cardCvvErrors = [ 'invalid_cvc', 'incorrect_cvc', 'incomplete_cvc' ];
      let hasError = false;

      switch (fieldId) {
        case 'card-number-element':
          if (cardNumberErrors.includes(this.stripeError.code)) {
            hasError = true;
          }
          break;
        case 'card-expiry-element':
          if (cardExpiryErrors.includes(this.stripeError.code)) {
            hasError = true;
          }
          break;
        case 'card-cvv-element':
          if (cardCvvErrors.includes(this.stripeError.code)) {
            hasError = true;
          }
          break;
      }

      return hasError;
    },
    fillFieldsWithPreviousValues() {
      const customerDetails = this.accountStatus.stripeCustomerInfo;
      const details = this.accountStatus.stripePaymentMethodDetails?.billingDetails;
      const taxDetails = this.accountStatus.stripePaymentMethodDetails?.taxDetails;
      const isBusiness = taxDetails?.type !== null;
      this.businessType = isBusiness ? 'company' : 'individual';
      if (details && details.name !== null) {
        const customerInfo = {
          ...{
            firstName: details.name.split(" ")[0],
            lastName: details.name.split(" ")[1],
            address: details.address.line1,
            city: details.address.city,
            postalCode: details.address.postalCode,
            phoneNumber: details.phone ? details.phone : '',
            businessType: isBusiness ? 'company' : 'individual',
          },
          ...(isBusiness && { companyName: customerDetails ? customerDetails.name : '' }),
          ...(isBusiness && { vatId: taxDetails.value.split("DK")[1] }),
        };

        for (const field in customerInfo) {
          this[field] = customerInfo[field];
        }
      }
    },
  },
};
</script>