<template>
    <div>
        <div class="checkDomainContainer">
            <h3 class="mds-heading-three">
                {{ $t('accountCenter.domainManagement.domainCheckerHeading') }}
            </h3>
            <p class="mds-body-large body">
                {{ $t('accountCenter.domainManagement.domainCheckerBody') }}
            </p>
            <domain-checker />
            <div class="skip-domain-container mds-margin-64">
                {{ $t('accountCenter.domainManagement.alreadyHaveDomain') }} <a class="mds-link" @click="skipRegisterDomain">{{ $t('accountCenter.domainManagement.skipStep') }}</a>
            </div>
        </div>
    </div>
</template>

<script>

import DomainChecker from '@/components/DomainChecker';

export default {
    name: 'UpgradeDomain',
    components: { DomainChecker },
    methods: {
        skipRegisterDomain() {
            this.$store.dispatch('upgradeflow/setDomainRegistrationSkipped', true);
            this.$router.push({ path: '/upgrade/subscription' });
        }
    }
};
</script>
