import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'
import watchers from './watchers'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  plugins: [],
  strict: process.env.NODE_ENV !== 'production'
});

//Add watcher modules to update API/other non-component related updates
for (let watcher in watchers) {
  for (let handler in watchers[watcher]) {
    if (handler.indexOf('_') !== 0) {
      store.watch(...watchers[watcher][handler](store));
    }
  }
}

export default store;
