import userlimits from '@/utils/userlimits';

export default {
    install(Vue){
        Vue.prototype.$userlimits = userlimits;

        /* istanbul ignore next */
        Vue.directive('userlimit', (el, binding) => {
            let limitString = binding.value,
                hideClass = binding.arg === 'visibility' ? 'userlimitHiddenV' : 'userlimitHidden';

            if( userlimits.check(limitString) ){
                el.classList.remove(hideClass);
            } else {
                el.classList.add(hideClass);
            }
        });
    }
};