<template>
    <div class="fullWidthWrapper">
        <div class="mainWrapper">
            <div class="upgradeHeader">
                <div>
                    <router-link :style="`background-image: url(${logoImage});background-color: ${logoColor}`" to="/" class="logo" />
                </div>
                <div class="closeIconContainer">
                    <router-link :to="routeBeforeEnterUpgradeFlow" class="closeIcon">
                        <mv-icon type="close" :size="24" />
                    </router-link>
                </div>
            </div>
            <router-view class="upgradeView" />
        </div>
        <mv-icon-sprite />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MvIcon, MvIconSprite } from '@monosolutions/vue-components';
import { get } from 'lodash';

export default {
    name: 'upgrade-flow',
    components: { MvIcon, MvIconSprite },
    data() {
        return {
            routeBefore: {
                fullPath: '/'
            }
        };
    },
    computed: {
        ...mapGetters({
            routeBeforeEnterUpgradeFlow: 'upgradeflow/routeBeforeEnterUpgradeFlow',
        }),
        logoImage() {
            return this.reseller && this.reseller.settings && this.reseller.settings.upgradeFlowLogo ? `/ii/${this.reseller.settings.upgradeFlowLogo}` : '../../assets/imgs/mono-logo-black.svg';
        },
        logoColor() {
            return this.reseller && get(this.reseller.settings, 'logoColor', undefined);
        }
    },
    mounted() {
        this.$store.dispatch('accountcenter/hideNavigation', true);
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('upgradeflow/setRouteBeforeEnterUpgradeFlow', '/account');
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('accountcenter/hideNavigation', false);
        next();
    },
};
</script>
