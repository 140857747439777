import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            headline: '',
        };
    },
    computed: {
        ...mapGetters({
            accountInfo: 'accountcenter/accountInfo',
            accountCenterSettings: 'accountcenter/accountCenterSettings'
        }),
        currency() {
            return this.accountCenterSettings.currency;
        },
        priceFormattingLocale() {
            return this.accountCenterSettings.priceFormattingLocale;
        },
    },
    methods: {
        formatPrice(value) {
            return Intl.NumberFormat(this.priceFormattingLocale, { style: 'currency', currency: this.currency }).format(value);
        },
        isDkDomain(domainName) {
            return domainName.substr(-3) === '.dk';
        }
    }
};