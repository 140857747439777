const engine = require('store/src/store-engine');
const localStorage = require('store/storages/localStorage');
const sessionStorage = require('store/storages/sessionStorage');
const plugins = [ require('store/plugins/json2') ];

const $ls = engine.createStore([ localStorage ], plugins);
const $ss = engine.createStore([ sessionStorage ], plugins);

export default {
    install(Vue){
        //LocalStorage store
        Vue.prototype.$ls = $ls;

        //Session storage store
        Vue.prototype.$ss = $ss;
    }
};

export { $ls, $ss };