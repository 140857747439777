import axios from 'axios';

axios.defaults.withCredentials = true;

const state = {
  hideNavigation: false,
  authenticated: false,
  accountInfo: {
    siteId: '',
    accountId: '',
    resellerId: '',
    siteUserGroups: ''
  },
  accountData: {},
  siteInfo: {
    email: '',
    password: '',
    id: '',
  },
  domainSiteInfo: [],
  userRole: '',
  limitations: {},
  subscriptionLimitations: {},
  accountCenterSettings: {},
  accountStatus: {
    status: '',
    trialDaysLeft: null,
    stripeCustomer: false,
    stripePaymentMethod: false,
    stripePaymentMethodDetails: null,
    domainBought: false
  }
};

const mutations = {
  SET_HIDE_NAVIGATION(state, newState) {
    state.hideNavigation = newState;
  },
  SET_AUTHENTICATED(state, authenticated) {
    state.authenticated = authenticated;
  },
  SET_ACCOUNTINFO(state, accountInfo) {
    state.accountInfo = Object.assign({}, state.accountInfo, accountInfo);
  },
  SET_ACCOUNTDATA(state, accountData) {
    state.accountData = Object.assign({}, state.accountData, accountData);
  },
  SET_SITEINFO(state, siteInfo) {
    state.siteInfo = Object.assign({}, state.siteInfo, siteInfo);
  },
  SET_DOMAINSITEINFO(state, domainSiteInfo) {
    state.domainSiteInfo = domainSiteInfo;
  },
  SET_SETTINGS(state, accountCenterSettings) {
    state.accountCenterSettings = Object.assign({}, state.accountCenterSettings, accountCenterSettings);
  },
  SET_ACCOUNTSTATUS(state, accountStatus) {
    state.accountStatus = Object.assign({}, state.accountStatus, accountStatus);
  }
};

const actions = {
  'hideNavigation'({ commit }, value) {
    commit('SET_HIDE_NAVIGATION', value);
  },
  setAuthenticated({ commit }, authenticated = {}) {
    commit('SET_AUTHENTICATED', authenticated);
  },
  setAccountInfo({ commit }, accountInfo = {}) {
    commit('SET_ACCOUNTINFO', accountInfo);
  },
  setSiteInfo({ commit }, siteInfo = {}) {
    commit('SET_SITEINFO', siteInfo);
  },
  setDomainSiteInfo({ commit }, domainSiteInfo = []) {
    commit('SET_DOMAINSITEINFO', domainSiteInfo);
  },
  setAccountStatus({ commit }, accountStatus = {}) {
    commit('SET_ACCOUNTSTATUS', accountStatus);
  },
  setSettings({ commit }, accountCenterSettings = {}) {
    commit('SET_SETTINGS', accountCenterSettings);
  },
  async getAccountStatus({ commit }) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.get(
      `${accountCenterBaseUrl}accounts/self/status`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
      }
    );

    commit('SET_ACCOUNTSTATUS', response.data.status);

    return response.data.status;
  },
  async getAccountData({ commit }) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.get(
      `${accountCenterBaseUrl}accounts/self`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
      }
    );

    commit('SET_ACCOUNTDATA', response.data.account);

    return response.data.account;
  },
  async updateAccount({ commit }, params) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.patch(
      `${accountCenterBaseUrl}accounts/self`, params,
      {
        headers: {
          'Content-Type': 'application/json'
        },
      }
    );

    commit('SET_ACCOUNTDATA', response.data.account);

    return response;
  },
  async getSiteDomains({ commit }) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.post(
      `${accountCenterBaseUrl}accounts/site/domains`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    commit('SET_DOMAINSITEINFO', response.data.domains);

    return response;
  },
  async getSiteInfo({ commit }) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.post(
      `${accountCenterBaseUrl}accounts/site`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    commit('SET_SITEINFO', response.data.site);

    return response.data.site;
  },
  // eslint-disable-next-line no-unused-vars
  async updateSite({ commit }, params) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.post(
      `${accountCenterBaseUrl}accounts/site/update`, params,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).catch((error) => {
      if (error.response) {
        return error.response.data;
      }
    });

    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async getSubscriptions({ commit }) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.get(
      `${accountCenterBaseUrl}accounts/subscriptions`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return response.data.subscriptions;
  },
  // eslint-disable-next-line no-unused-vars
  async getLinkToEditor({ commit }) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.get(
      `${accountCenterBaseUrl}accounts/login/url`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return response.data.loginInfo;
  },
  // eslint-disable-next-line no-unused-vars
  async changeSubscriptionOnExpiry({ commit }, params) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.post(
      `${accountCenterBaseUrl}accounts/subscription/expiry/change`, params,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async terminateSubscription({ commit }, params) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.post(
      `${accountCenterBaseUrl}accounts/terminate`, params,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async removeSiteDomain({ commit }, params) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.post(
      `${accountCenterBaseUrl}accounts/site/domain/remove`,
      {
        headers: {
          'Content-Type': 'application/json'
        }, params
      }
    );

    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async setPrimaryDomain({ commit }, params) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.post(
      `${accountCenterBaseUrl}accounts/site/domain/set-primary`,
      {
        headers: {
          'Content-Type': 'application/json'
        }, params
      }
    );

    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async addSiteDomain({ commit }, params) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.post(
      `${accountCenterBaseUrl}accounts/site/domain`,
      {
        headers: {
          'Content-Type': 'application/json'
        }, params
      }
    );

    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async checkDomainIsAvailable({ commit }, params) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.post(
      `${accountCenterBaseUrl}accounts/site/domain/check`,
      {
        headers: {
          'Content-Type': 'application/json'
        }, params
      }
    );

    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async updatePaymentCard({ commit }, params) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.post(
      `${accountCenterBaseUrl}accounts/self/updatePaymentMethod`, params,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async updateTaxDetails({ commit }, params) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.post(
      `${accountCenterBaseUrl}accounts/self/update/tax`, params,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return response;
  },
  async getSettings({ commit }) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.get(
      `${accountCenterBaseUrl}accounts/settings`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    commit('SET_SETTINGS', response.data.settings);

    return response.data.settings;
  },
  // eslint-disable-next-line no-unused-vars
  async createSubscription({ commit }, params) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.post(
      `${accountCenterBaseUrl}accounts/self/stripe/flow`, params,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async verifyCupon({ commit }, params) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.get(
      `${accountCenterBaseUrl}accounts/stripe/coupon/${params.couponPromoCode}`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async createContactDK({ commit }, params) {
    const accountCenterBaseUrl = process.env.VUE_APP_AC_BASE_URL;

    const response = await axios.post(
      `${accountCenterBaseUrl}accounts/site/create/contact`, params,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return response;
  },
}

const getters = {
  hideNavigation: state => state.hideNavigation,
  authenticated: state => state.authenticated,
  accountInfo: state => state.accountInfo,
  accountData: state => state.accountData,
  siteInfo: state => state.siteInfo,
  domainSiteInfo: state => state.domainSiteInfo,
  userRole: state => state.userRole,
  limitations: state => state.limitations,
  subscriptionLimitations: state => state.subscriptionLimitations,
  accountStatus: state => state.accountStatus,
  accountCenterSettings: state => state.accountCenterSettings
};

export default {
  state,
  mutations,
  actions,
  getters
};
