<template>
  <div 
    class="home subscriptions-container"
    :class="{ 'open-modal': updatePaymentModalShow, loading: isLoading }"
  >
    <header-element
      headline="accountCenter.subscription.headline"
      :hasFirstButton="false"
    />
    <limitation-element
      :showLimitationModal="isPremium && terminateLimitation.show"
      :color="terminateLimitation.color"
      firstHeadline="accountCenter.subscription.terminateLimitation.headline"
      @modal-close-clicked="closeLimitationModal"
    >
      <div slot="limitation-content">
        <p>{{ $t('accountCenter.subscription.terminateLimitation.firstLine') + expireDate + $t('accountCenter.subscription.terminateLimitation.secondLine') }}<a href="javascript:void(0)" @click="changePremiumSubscriptionStatus('renew')" style="color: #3E18B8">{{ $t('accountCenter.subscription.terminateLimitation.link') }}</a>.</p>
      </div>
    </limitation-element>
    <div class="subscription-wrapper">
      <content-wrapper>
          <div slot="content-wrapper-content">
            <p class="headline"> {{ subscriptionType }} </p>
            <status-element :has-status="isPremium" :color="color" text="accountCenter.subscription.premium" :status-icon="statusIcon" />
            <mv-button class="button mds-button-white more-options-button" size="medium" text="accountCenter.subscription.buttons.moreOptions" @click="openDropdown" />
            <mv-button v-if="!isPremium" class="button mds-button-purple upgrade-button" size="medium" text="accountCenter.subscription.buttons.upgrade" @click="openUpgradeFlow" />
            <settings-dropdown :expanded="showDropdown" :settings="settings" @showTerminateWebsite="showTerminateWebsite" @updatePayment="updatePayment" @terminateWebsitePremium="terminateWebsitePremium" />
          </div>
      </content-wrapper>
    </div>

    <!-- Terminate MODAL -->
    <div v-if="terminateModalShow" class="modal-overlay terminate-modal">
      <terminate-modal :secondButtonLoading="loadingFreeCancel" @terminateFreeWebsite="terminateFreeWebsite" @closeTerminateModal="closeTerminateModal" />
    </div>

    <!-- Confirmation Terminate MODAL -->
    <div v-if="confirmationModalShow" class="modalWrapper modal-overlay confirmation-modal">
      <mv-modal
        title="accountCenter.subscription.cancelllation.title"
        description="accountCenter.subscription.cancelllation.description"
        :firstButton="false"
        secondButtonText="general.ok"
        @second-button-clicked="closeConfirmationModal"
        @modal-close-clicked="closeConfirmationModal"
      />
    </div>

    <!-- Update Payment MODAL -->
    <div v-if="updatePaymentModalShow" class="modal-overlay payment-modal">
      <payment-modal :stripe="stripe" :email="email"
        @savePaymentMethod="savePaymentMethod" 
        @closeUpdatePaymentModal="closeUpdatePaymentModal" />
    </div>

    <!-- Terminate Subscription flow MODAL -->
    <div v-if="terminateSubscriptionFlow.show" class="modal-overlay terminate-modal">
      <modal-wizard v-if="terminateSubscriptionFlow.show" :roundedCorners="true">
        <step-content 
          title="accountCenter.subscription.terminateModal.stepOne.title"
          titlePosition="center"
          firstButtonText="accountCenter.subscription.terminateModal.buttons.keepSite"
          firstButtonColor="white"
          :firstButton="true"
          secondButtonText="accountCenter.subscription.terminateModal.buttons.cancelNow"
          secondButtonColor="red"
          @firstButtonClicked="closeTerminateSubscriptionFlowModal"
          @secondButtonClicked="changeStep(1)"
          @modalCloseClicked="closeTerminateSubscriptionFlowModal"
        >
          <div slot="step-content">
            <h4 class="mds-heading-four terminate-title">{{ $t('accountCenter.subscription.terminateModal.stepOne.preamble') }}</h4>
            <p class="terminate-paragraph">{{ $t('accountCenter.subscription.terminateModal.stepOne.secondLine') }}</p>
            <span class="icon"><p class="terminate-paragraph icon">{{ $t('accountCenter.subscription.terminateModal.stepOne.paragraphOne') }}</p></span>
            <span class="icon"><p class="terminate-paragraph icon">{{ $t('accountCenter.subscription.terminateModal.stepOne.paragraphTwo') }}</p></span>
            <span class="icon"><p class="terminate-paragraph icon">{{ $t('accountCenter.subscription.terminateModal.stepOne.paragraphFour') }}</p></span>
            <span class="icon"><p class="terminate-paragraph icon">{{ $t('accountCenter.subscription.terminateModal.stepOne.paragraphFive') }}</p></span>          
          </div>
        </step-content>
        <step-content 
          title="accountCenter.subscription.terminateModal.stepTwo.title"
          titlePosition="center"
          :firstButton="true"
          firstButtonText="accountCenter.subscription.terminateModal.buttons.keepPlan"
          firstButtonColor="white"
          secondButtonText="accountCenter.subscription.terminateModal.buttons.cancelNow"
          secondButtonColor="red"
          :secondButtonDisabled="terminateSubscriptionFlow.cancelDisabled"
          :isLoadingSecond="terminateSubscriptionFlow.cancelLoading"
          @firstButtonClicked="closeTerminateSubscriptionFlowModal"
          @secondButtonClicked="changePremiumSubscriptionStatus('terminate')"
          @modalCloseClicked="closeTerminateSubscriptionFlowModal"
        >
          <div slot="step-content">
            <h4 class="mds-heading-four terminate-title second-step">{{ $t('accountCenter.subscription.terminateModal.stepTwo.preamble') }}</h4>
            <p class="terminate-paragraph second-step">{{ $t('accountCenter.subscription.terminateModal.stepTwo.secondLine') }}</p>
            <input-select :options="terminateSubscriptionFlow.options" @change="setData($event, 'motive')" />
            <input-text class="mds-input-text" label="accountCenter.subscription.terminateModal.stepTwo.inputLabel" index="more" :value="terminateSubscriptionFlow.more" @change="setData"/>
            <p class="terminate-paragraph last-line">{{ $t('accountCenter.subscription.terminateModal.stepTwo.lastLine') + expireDate }}.</p>
          </div>
        </step-content>
      </modal-wizard>
    </div>
    <mv-icon-sprite />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { set, merge } from 'lodash';
import moment from 'moment';
import {
  ContentWrapper,
  Header,
  MvIconSprite,
  ElementStatus,
  MvButton,
  SettingsDropdown,
  ModalWizard,
  StepContent,
  InputSelect,
  InputText,
  Limitation,
  MvModal
} from "@monosolutions/vue-components";
import { $ss } from '@/plugins/localstorage';
import PaymentModal from '@/components/modals/PaymentModal.vue';
import TerminateModal from '@/components/modals/TerminateModal.vue';
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: "ManageSubscriptions",
  components: {
    ContentWrapper,
    "header-element": Header,
    MvIconSprite,
    "status-element": ElementStatus,
    MvButton,
    SettingsDropdown,
    ModalWizard,
    StepContent,
    PaymentModal,
    TerminateModal,
    InputSelect,
    InputText,
    MvModal,
    "limitation-element": Limitation
  },
  data: () => {
    return {
      subscriptionType: '',
      isPremium: false,
      color: 'purple',
      statusIcon: 'star-fill',
      showDropdown: false,
      subscriptions: [],
      expireDate: '',
      updatePaymentModalShow: false,
      terminateSubscriptionFlow: {
        show: false,
        cancelDisabled: true,
        cancelLoading: false,
        options: [],
        more: ''
      },
      stripe: null,
      email: '',
      cancelReasons: {},
      terminateLimitation: {
        show: false,
        color: "yellow"
      },
      isLoading: false,
      terminateModalShow: false,
      confirmationModalShow: false,
      loadingFreeCancel: false,
      accountCenterSettings: []
    };
  },
  computed: {
    ...mapGetters({
      siteInfo: "accountcenter/siteInfo",
    }),
    settings() {
      let settings = [];
      if (!this.isPremium) {
        settings.push({ text: this.$t('accountCenter.subscription.options.terminate'), action: 'showTerminateWebsite', mark: true });
      } else if (this.isPremium && !this.terminateLimitation.show) {
        settings.push({ text: this.$t('accountCenter.subscription.options.updatePayment'), action: 'updatePayment' },
                      { text: this.$t('accountCenter.subscription.options.cancel'), action: 'terminateWebsitePremium', mark: true });
      } else {
        settings.push({ text: this.$t('accountCenter.subscription.options.updatePayment'), action: 'updatePayment' });
      }
      return settings;
    },
  },
  async mounted() {
    this.accountCenterSettings = await this.$store.dispatch("accountcenter/getSettings");
    this.subscriptionType = this.siteInfo.siteUserGroups === 'mono_site_start' ? this.$t('accountCenter.subscription.type.free') : this.$t('accountCenter.subscription.type.professional');
    this.isPremium = this.siteInfo.siteUserGroups !== 'mono_site_start';
    this.email = this.siteInfo.email;
    this.initializeStripe();
    this.setOptions();
    this.subscriptions = await this.$store.dispatch("accountcenter/getSubscriptions");
    this.expireDate = moment(String(this.subscriptions[0].expireDate)).format('DD/MM/YYYY');
    this.terminateLimitation.show = this.subscriptions[0].onExpiry === "TERMINATE";
    document.addEventListener('click', this.closeDropdown);
  },
  beforeDestroy () {
    document.removeEventListener('click', this.closeDropdown);
  },
  methods: {
    async initializeStripe() {
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY || this.accountCenterSettings.stripe.publishableApiKey);
      this.stripe = stripe;
    },
    openDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    closeDropdown(e) {
      if(e.target.tagName !== 'A') {
        this.showDropdown = false;
      }
    },
    showTerminateWebsite() {
      this.terminateModalShow = true;
    },
    async terminateFreeWebsite() {
      this.loadingFreeCancel = true;
      await this.$store.dispatch("accountcenter/terminateSubscription", { email: this.email });
      this.loadingFreeCancel = false;
      this.confirmationModalShow = true;
      this.terminateModalShow = false;
      this.showDropdown = false;
    },
    async closeConfirmationModal() {
      if (!this.isPremium) {
        this.$store.dispatch('accountcenter/hideNavigation', true);
        await this.$store.dispatch('accountcenter/setAuthenticated', false);
        $ss.remove('user');
        $ss.remove('jwtToken');
        this.$cookies.remove('sid');
        this.$router.replace({ name: "login" });
      }
      this.confirmationModalShow = false;
    },
    terminateWebsitePremium() {
      this.terminateSubscriptionFlow.show = true;
      this.showDropdown = false;
    },
    async changePremiumSubscriptionStatus(status) {
      this.terminateSubscriptionFlow.cancelLoading = true;
      await this.$store.dispatch("accountcenter/changeSubscriptionOnExpiry", { subscriptionStatus: status, email: this.email });
      this.terminateSubscriptionFlow.cancelLoading = false;
      if (status === 'terminate') {
        this.terminateSubscriptionFlow.show = false;
        this.confirmationModalShow = true;
        this.terminateLimitation.show = true;
        this.addHeapTerminateReasons();
      } else if (status === 'renew') {
        this.terminateLimitation.show = false;
      }
    },
    setData(payload, motive) {
      const objData = {};
      if (payload.index !== '') {
        set(objData, payload.index, payload.data.value);
      } else if (payload.data.value !== 'reason') {
        set(objData, motive, payload.data.value);
        this.terminateSubscriptionFlow.cancelDisabled = false;
      } else {
        this.terminateSubscriptionFlow.cancelDisabled = true;
      }
      this.cancelReasons = merge({}, this.cancelReasons, objData);
    },
    setOptions() {
      this.terminateSubscriptionFlow.options.push(
          { index: 'reason', value: 'reason', text: this.$t('accountCenter.subscription.terminateModal.options.reason') },
          { index: 'expensive', value: 'too_expensive', text: this.$t('accountCenter.subscription.terminateModal.options.expensive') },
          { index: 'work', value: 'didnt_work_properly', text: this.$t('accountCenter.subscription.terminateModal.options.work') },
          { index: 'need', value: 'dont_need_anymore', text: this.$t('accountCenter.subscription.terminateModal.options.need') },
          { index: 'alternative', value: 'found_better_alternative', text: this.$t('accountCenter.subscription.terminateModal.options.alternative') },
          { index: 'difficult', value: 'too_difficult', text: this.$t('accountCenter.subscription.terminateModal.options.difficult') },
          { index: 'other', value: 'other', text: this.$t('accountCenter.subscription.terminateModal.options.other') });
    },
    async updatePayment() {
      this.updatePaymentModalShow = true;
      this.showDropdown = false;
    },
    openUpgradeFlow() {
      this.$router.push({ path: '/upgrade/check-domain' });
    },
    savePaymentMethod() {
      this.updatePaymentModalShow= false;
    },
    closeUpdatePaymentModal() {
      this.updatePaymentModalShow = false;
    },
    closeTerminateModal() {
      this.terminateModalShow = false;
      this.showDropdown = false;
    },
    closeTerminateSubscriptionFlowModal() {
      this.terminateSubscriptionFlow.show = false;
    },
    changeStep(step) {
      this.$root.$emit('modalWizardNexStep',step);
    },
    closeLimitationModal() {
      this.terminateLimitation.show = false;
    },
    addHeapTerminateReasons() {
      if (window.heap) {
        window.heap.addUserProperties({
          siteId: (this.siteInfo.id).toString(),
          subscriptionType: 'prof_terminated',
          terminationReason: this.cancelReasons['motive'],
          moreInfoTerminate: this.cancelReasons['more']
        });
      }
    }
  }
};
</script>